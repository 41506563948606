#savings {
  // custom code for savings page
  .savings {
    &__title {
      .cmp-title__text {
        margin-top: 20px;
        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          margin-top: 25px;
        }
      }
    }
    &__desc {
      p {
        letter-spacing: 1.05px;
        margin-bottom: 25px;
        @include mq('medium') {
          margin-bottom: 20px;
        }
      }
    }
  }

  .savings-box {
    position: relative;
    &--image {
      &-mobile {
        height: 400px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      &-tablet {
        height: 407px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &-desktop {
        height: 330px;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &--content-container {
      padding-top: 10px;
      padding-bottom: 20px;
      padding-left: 20px;

      @include mq('medium') {
        background-color: var(--emu-semantic-colors-none);
        width: 37%;
        position: absolute;
        top: var(--emu-common-spacing-none);
        right: var(--emu-common-spacing-none);
        margin-right: 27px;
        padding-top: var(--emu-common-spacing-none);
        padding-left: var(--emu-semantic-spacing-horizontal);
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-top: 20px;
      }

      .aaaem {
        &-title {
          h5 {
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 2.7px;
            font-weight: normal;
          }
        }

        &-text {
          p {
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.9px;
            margin-bottom: 10px;
            padding-right: var(--emu-semantic-spacing-horizontal);
            @include mq('medium') {
              font-size: 12px;
              padding-right: 25px;
            }
          }
        }

        &-button {
          color: var(--emu-semantic-colors-primary-red-100);
          border-color: var(--emu-semantic-colors-primary-red-100);

          &:hover {
            color: var(--emu-semantic-colors-primary-red-100);
            background-color: var(--emu-semantic-colors-primary-red-400);
            border-color: var(--emu-semantic-colors-primary-red-100);
          }
        }
      }
    }
  }

  .savings-alle {
    &__container {
      padding-top: 10px;
      padding-right: 45px;
      padding-left: 45px;
      padding-bottom: var(--emu-semantic-spacing-horizontal);

      .container.responsivegrid {
        width: 100%;
      }

      @include mq('medium') {
        padding-right: var(--emu-semantic-spacing-none);
        padding-left: var(--emu-semantic-spacing-none);
        padding-bottom: 25px;
      }

      @include mq('992px') {
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    &__content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      > .container {
        position: relative;

        .aaaem-container {
          margin-bottom: 50px;
        }

        &::before {
          content: '';
          position: absolute;
          top: -50px;
          left: 50%;
          width: 1px;
          height: 40px;
          background-color: var(
            --emu-semantic-colors-secondary-light-grey-600
          );
        }

        &:first-child::before {
          display: none;
        }

        &:last-child {
          .aaaem-container {
            margin-bottom: var(--emu-semantic-spacing-none);
          }
        }

        @include mq('medium') {
          &::before {
            display: none;
          }

          &::after {
            content: '';
            position: absolute;
            top: 36px;
            left: 100%;
            width: 70%;
            height: 1px;
            background-color: var(
              --emu-semantic-colors-secondary-light-grey-600
            );
            transform: translateX(-50%);
          }

          &:last-child::after {
            display: none;
          }

          .aaaem-container {
            margin-bottom: var(--emu-semantic-spacing-none);
          }
        }
      }

      @include mq('medium') {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    &__title {
      .cmp-title__text {
        margin-top: var(--emu-semantic-spacing-horizontal);
        margin-bottom: 10px;

        @include mq('medium') {
          margin-top: 35px;
          margin-bottom: 50px;
        }
      }
    }

    &__number {
      margin-left: auto;
      margin-right: auto;
      width: 70px;

      p {
        font-size: 36px;
        border-color: #fff;
        border-style: solid;
        border-width: 1px;
        border-radius: 50%;
        padding-top: 22px;
        padding-right: 20px;
        padding-bottom: 24px;
        padding-left: 22px;
      }
    }

    &__desc {
      p {
        padding-top: 10px;
        letter-spacing: 1.05px;
      }

      &-small {
        p {
          letter-spacing: 0.9px;
          padding-top: var(--emu-semantic-spacing-none);
          padding-right: var(--emu-semantic-spacing-horizontal);
          padding-bottom: 10px;
          padding-left: var(--emu-semantic-spacing-horizontal);
        }
      }
    }
  }

  .savings-get-the-app {
    position: relative;

    &__content-container {
      padding-top: 10px;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 20px;

      @include mq('medium') {
        padding-top: 30px;
        padding-right: 10px;
        padding-left: 58.3%;
      }
      .cmp-title__text {
        letter-spacing: 0.9px;
        @include mq('992px') {
          line-height: 38px;
        }
      }
      .aaaem-text {
        margin: var(--emu-semantic-spacing-none);
        p {
          letter-spacing: 0.9px;
          margin-top: 10px;
        }
        ul {
          margin: var(--emu-semantic-spacing-none);
          padding-left: 20px;
          li {
            letter-spacing: 0.9px;
            margin-top: var(--emu-semantic-spacing-horizontal);
          }
        }
      }
      .button {
        z-index: 1;
        .aaaem-button {
          margin-top: 39px;
        }
      }
    }
    &__image-container {
      position: relative;
      @include mq('medium') {
        height: 425px;
      }
      img {
        width: 95%;
        position: relative;
        overflow: hidden;
        @include mq('medium') {
          position: absolute;
          top: -125px;
          width: 100%;
          z-index: 0;
        }
        @include mq('992px') {
          top: -175px;
          width: 80%;
        }
      }
    }
  }

  .savings-benefits {
    &__container {
      padding: var(--emu-semantic-spacing-none);
      padding-bottom: 40px;
      @include mq('medium') {
        padding-top: 20px;
      }
    }

    &__title {
      .cmp-title__text {
        margin-top: 30px;
      }
    }

    &__desc {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__content {
      &-container {
        padding: var(--emu-semantic-spacing-none);
        @include mq('medium') {
          padding-top: var(--emu-semantic-spacing-none);
          padding-right: 75px;
          padding-bottom: var(--emu-semantic-spacing-none);
          padding-left: 75px;
        }

        .button,
        .image {
          display: flex;
          justify-content: center;
        }

        .aaaem-image {
          width: 100px;
          height: 70px;
          img {
            height: 100%;
            margin-top: var(--emu-semantic-spacing-none);
            margin-right: auto;
            margin-bottom: var(--emu-semantic-spacing-none);
            margin-left: auto;
          }
        }
      }

      &-title {
        .cmp-title__text {
          font-size: 18px;
          line-height: 26px;
          padding-top: 25px;
          @include mq('medium') {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }

      &-desc {
        padding-right: var(--emu-semantic-spacing-horizontal);
        padding-left: var(--emu-semantic-spacing-horizontal);
        padding-bottom: 25px;
        @include mq('medium') {
          padding-bottom: var(--emu-semantic-spacing-none);
        }
        p {
          font-size: 15px;
          line-height: 21px;
          @include mq('medium') {
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.9px;
          }
        }
      }

      &-button {
        color: var(--emu-semantic-colors-primary-red-200);
        border-color: var(--emu-semantic-colors-primary-red-200);

        &:hover {
          background-color: var(--emu-semantic-colors-primary-red-400);
        }
      }
    }
  }
}
