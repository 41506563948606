
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FAFAFA !default;
$emu-semantic-colors-surface-dark: #F5F5F5 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FFFFFF !default;
$emu-semantic-colors-actions-primary-dark: #DD6F66 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-text-light: #4E5457 !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-red-100: #AE504B !default;
$emu-semantic-colors-primary-red-200: #DD6F66 !default;
$emu-semantic-colors-primary-red-300: #773D3A !default;
$emu-semantic-colors-primary-red-400: #F0DAD8 !default;
$emu-semantic-colors-secondary-red-100: #ff0000 !default;
$emu-semantic-colors-secondary-blue-100: #137193 !default;
$emu-semantic-colors-secondary-blue-200: #2A6496 !default;
$emu-semantic-colors-secondary-dark-grey-100: #3E3E3E !default;
$emu-semantic-colors-secondary-dark-grey-200: #4D4D4D !default;
$emu-semantic-colors-secondary-dark-grey-300: #4E5355 !default;
$emu-semantic-colors-secondary-dark-grey-400: #4E5457 !default;
$emu-semantic-colors-secondary-dark-grey-500: #666666 !default;
$emu-semantic-colors-secondary-dark-grey-600: #D1D1D1 !default;
$emu-semantic-colors-secondary-dark-grey-700: #333333 !default;
$emu-semantic-colors-secondary-dark-grey-800: #555 !default;
$emu-semantic-colors-secondary-light-grey-100: #F5F5F5 !default;
$emu-semantic-colors-secondary-light-grey-200: #FAFAFA !default;
$emu-semantic-colors-secondary-light-grey-300: #B1B1B1 !default;
$emu-semantic-colors-secondary-light-grey-400: #EBECED !default;
$emu-semantic-colors-secondary-light-grey-500: #A8A8A8 !default;
$emu-semantic-colors-secondary-light-grey-600: #FFFFFF !default;
$emu-semantic-colors-secondary-light-grey-700: rgba(255, 255, 255, 0.16) !default;
$emu-semantic-colors-secondary-light-grey-800: #C6C6C6 !default;
$emu-semantic-colors-secondary-light-grey-900: #ebebeb !default;
$emu-semantic-colors-secondary-light-grey-1000: #ccc !default;
$emu-semantic-colors-secondary-light-grey-1100: #f6f6f6 !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-regular: 300 !default;
$emu-semantic-font-families-body: 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-font-families-heading: 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-font-families-mono: 'roboto-condensed', sans-serif  !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 15px !default;
$emu-semantic-spacing-vertical: 15px !default;
$emu-semantic-font-sizes-narrow-medium: 9px !default;
$emu-semantic-font-sizes-narrow-large: 12px !default;
$emu-semantic-font-sizes-narrow-xl: 14px !default;
$emu-semantic-font-sizes-narrow-xxl: 20px !default;
$emu-semantic-font-sizes-narrow-xxxl: 24px !default;
$emu-semantic-font-sizes-wide-medium: 9px !default;
$emu-semantic-font-sizes-wide-large: 12px !default;
$emu-semantic-font-sizes-wide-xl: 14px !default;
$emu-semantic-font-sizes-wide-xxl: 20px !default;
$emu-semantic-font-sizes-wide-xxxl: 24px !default;
$emu-semantic-line-heights-narrow-medium: 13px !default;
$emu-semantic-line-heights-narrow-large: 17px !default;
$emu-semantic-line-heights-narrow-xl: 20px !default;
$emu-semantic-line-heights-narrow-xxl: 28px !default;
$emu-semantic-line-heights-narrow-xxxl: 30px !default;
$emu-semantic-line-heights-wide-medium: 13px !default;
$emu-semantic-line-heights-wide-large: 17px !default;
$emu-semantic-line-heights-wide-xl: 22px !default;
$emu-semantic-line-heights-wide-xxl: 28px !default;
$emu-semantic-line-heights-wide-xxxl: 30px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 320px !default;
$emu-semantic-sizing-breakpoints-medium: 768px !default;
$emu-semantic-sizing-breakpoints-large: 990px !default;
$emu-semantic-sizing-breakpoints-x-large: 992px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1440px !default;
$emu-semantic-sizing-one-line-height-wide: 13px !default;
$emu-semantic-sizing-one-line-height-narrow: 13px !default;
$emu-semantic-typography-narrow-headings-xxxl: 300 24px/30px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-narrow-headings-xxl: 300 20px/28px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-narrow-headings-xl: 300 20px/25px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-narrow-headings-large: 300 24px/28px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-narrow-headings-medium: 700 12px/17px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-narrow-body-regular: 300 12px/17px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-headings-xxxl: 300 24px/28px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-headings-xxl: 300 20px/28px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-headings-xl: 300 20px/25px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-headings-large: 300 14px/17px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-headings-medium: 700 9px/13px 'nimbus-sans-extended', sans-serif !default;
$emu-semantic-typography-wide-body-regular: 300 12px/17px 'nimbus-sans-extended', sans-serif !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'red': (
          '100': $emu-semantic-colors-primary-red-100,
          '200': $emu-semantic-colors-primary-red-200,
          '300': $emu-semantic-colors-primary-red-300,
          '400': $emu-semantic-colors-primary-red-400
        )
      ),
      'secondary': (
        'red': (
          '100': $emu-semantic-colors-secondary-red-100
        ),
        'blue': (
          '100': $emu-semantic-colors-secondary-blue-100,
          '200': $emu-semantic-colors-secondary-blue-200
        ),
        'darkGrey': (
          '100': $emu-semantic-colors-secondary-dark-grey-100,
          '200': $emu-semantic-colors-secondary-dark-grey-200,
          '300': $emu-semantic-colors-secondary-dark-grey-300,
          '400': $emu-semantic-colors-secondary-dark-grey-400,
          '500': $emu-semantic-colors-secondary-dark-grey-500,
          '600': $emu-semantic-colors-secondary-dark-grey-600,
          '700': $emu-semantic-colors-secondary-dark-grey-700,
          '800': $emu-semantic-colors-secondary-dark-grey-800
        ),
        'lightGrey': (
          '100': $emu-semantic-colors-secondary-light-grey-100,
          '200': $emu-semantic-colors-secondary-light-grey-200,
          '300': $emu-semantic-colors-secondary-light-grey-300,
          '400': $emu-semantic-colors-secondary-light-grey-400,
          '500': $emu-semantic-colors-secondary-light-grey-500,
          '600': $emu-semantic-colors-secondary-light-grey-600,
          '700': $emu-semantic-colors-secondary-light-grey-700,
          '800': $emu-semantic-colors-secondary-light-grey-800,
          '900': $emu-semantic-colors-secondary-light-grey-900,
          '1000': $emu-semantic-colors-secondary-light-grey-1000,
          '1100': $emu-semantic-colors-secondary-light-grey-1100
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'medium': $emu-semantic-line-heights-narrow-medium,
        'large': $emu-semantic-line-heights-narrow-large,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-line-heights-wide-medium,
        'large': $emu-semantic-line-heights-wide-large,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      )
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular
        )
      )
    )
  )
);
