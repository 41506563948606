.aaaem-carousel {
  background-color: var(--emu-semantic-colors-secondary-light-grey-100);

  .tns-outer [data-action] {
    display: none;
  }

  &__indicators {
    position: relative;
    bottom: 10px;
    left: 0px;
  }

  @include mq('medium') {
    background-color: var(--emu-semantic-colors-none);
    &__indicators {
      position: absolute;
      bottom: 20px;
      left: 47%;
    }

    &__indicator {
      border-color: var(--emu-semantic-colors-secondary-light-grey-600);
      background-color: var(--emu-semantic-colors-none);

      &.tns-nav-active {
        background-color: var(--emu-semantic-colors-secondary-light-grey-600);
        border-color: var(--emu-semantic-colors-secondary-light-grey-600);
      }
    }
  }
}
