.header {
  &__nav-wrapper {
    position: absolute;
    top: 85px;
    left: 0;
    width: 100%;
    max-height: var(--emu-semantic-sizing-none);
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.6s linear;

    &.js-toggle-on {
      max-height: calc(100vh - 85px);
      @include mq('medium') {
        max-height: none;
      }
    }

    @include mq('medium') {
      max-height: none;
      position: static;
      overflow: visible;
    }
  }
}

.emu-navigation {
  &__content-wrapper {
    position: static;

    nav {
      a {
        font-family: 'nimbus-sans-condensed', sans-serif;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1.2px;

        @include mq('medium') {
          font-family: var(--emu-semantic-font-families-body);
          font-size: var(--emu-semantic-font-sizes-wide-large);
          line-height: 20px;
          letter-spacing: 1.8px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-size: 11px;
          letter-spacing: 1.65px;
        }
      }

      > ul {
        @include mq('medium') {
          justify-content: space-between;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          justify-content: flex-end;
          position: relative;
          top: 10px;
        }

        > li {
          @include mq('medium') {
            > a,
            > span > a {
              white-space: nowrap;
            }

            &.emu-navigation__item-parent {
              position: relative;
              &:hover {
                background-color: #ebebeb; // no variable
              }

              ul {
                .emu-navigation__item--active {
                  a {
                    color: var(
                      --emu-component-lists-navigation-dropdown-item-text-color-default-light
                    );
                  }
                }
              }
            }

            &:hover {
              > a,
              > span {
                color: var(--emu-semantic-colors-primary-red-100);
              }
            }
          }

          > a {
            border-right: var(--emu-common-border-width-none);
            border-left: var(--emu-common-border-width-none);
            border-bottom: var(--emu-common-border-width-none);

            @include mq('medium') {
              border-top: var(--emu-common-border-width-none);
            }
          }

          > a,
          > span {
            @include mq('medium') {
              border: var(--emu-common-border-width-none);
              &:hover {
                color: var(--emu-semantic-colors-primary-red-200);
              }
            }
          }

          > span {
            @include mq('medium') {
              padding: var(--emu-semantic-spacing-none);
            }
          }

          > a,
          > span > a {
            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              padding-top: var(--emu-common-spacing-small);
              padding-right: 11px;
              padding-bottom: var(--emu-common-spacing-small);
              padding-left: 11px;
            }
          }

          ul {
            min-width: 140px;
            width: 100%;

            a {
              padding-right: var(
                --emu-component-lists-navigation-text-padding-left-wide
              );
              padding-left: 10px;
              letter-spacing: normal;
            }
          }

          &:nth-last-child(2),
          &:last-child {
            > a {
              color: #a4a4a4; // no variable
            }

            @include mq('medium') {
              display: none;
            }
          }

          &:nth-last-child(3) {
            a {
              @include mq('medium') {
                padding-right: var(--emu-common-spacing-xs);
              }

              @include mq($emu-semantic-sizing-breakpoints-x-large) {
                padding-right: var(--emu-semantic-spacing-none);
              }
            }
          }
        }
      }

      @include mq('medium') {
        .emu-navigation__item.emu-navigation__item--active > a {
          color: var(--emu-semantic-colors-primary-red-100);
        }
      }
    }
  }

  &__item {
    @include mq('medium') {
      // hiding non parent dropdown related elements
      &:nth-child(2),
      &:nth-child(4) {
        display: none;
      }
    }

    &-parent {
      display: none;

      @include mq('medium') {
        display: block;

        > ul > li.emu-navigation__item {
          display: block;
        }
      }
    }
  }
}
