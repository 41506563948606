.aaaem-text {
  letter-spacing: 1.2px;

  b {
    font-weight: var(--emu-common-font-weight-bold);
  }

  p {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }

  ul,
  li {
    margin-left: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  b,
  i,
  pre,
  sup,
  sub,
  u,
  pre,
  th,
  td,
  span {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  sup {
    font-size: 10px;
    line-height: 8px;
  }

  &.title--large,
  &.title--medium,
  &.title--small {
    h1,
    h2,
    h3 {
      sup {
        font-size: 10px;
        line-height: 8px;
      }
    }
  }

  &.title--large {
    h1 {
      font-size: 27px;
      line-height: 30px;
      letter-spacing: 4px;
      sup {
        font-size: 12px;
      }
    }
  }

  &.title--medium {
    h2,
    h3 {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 3px;
    }
  }

  &.title--small {
    h2,
    h3 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 3px;
    }
  }

  &.title--xsmall {
    h2,
    h3 {
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 2.5px;
    }
  }
}
