// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }
}

// padding adjustments
.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// custom utilities
.text--font-body {
  font-family: var(--emu-semantic-font-families-body);
}

.text--font-heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.text--font-mono {
  font-family: var(--emu-semantic-font-families-mono);
}

.text--color-white {
  color: var(--emu-semantic-colors-text-dark);
}

.text--color-light-grey {
  color: var(--emu-semantic-colors-secondary-light-grey-200);
}

.text--color-dark-grey {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

.text--color-regular {
  color: var(--emu-semantic-colors-text-light);
}

.text--color-red {
  color: var(--emu-semantic-colors-primary-red-100);
}

.text--color-blue {
  color: var(--emu-semantic-colors-secondary-blue-100);
}

.root > .aaaem-container {
  padding: 0;
}

.kyb-full-width-container,
.kyb-full-width-container.aaaem-container {
  width: 100%;
  max-width: none;
  padding: var(--emu-common-spacing-none);
}

.kyb-custom-container {
  padding: var(--emu-common-spacing-none);
  max-width: none;

  > .aem-Grid {
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    margin-left: auto;
    margin-right: auto;
    max-width: none;

    @include mq('medium') {
      max-width: var(--emu-semantic-sizing-breakpoints-medium);
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      max-width: var(--emu-semantic-sizing-breakpoints-large);
    }
  }
}

.bg--secondary-light-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-100);
}

.bg--secondary-regular-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-200);
}

.bg--secondary-medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-400);
}

.bg--secondary-dark-grey {
  background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

.bg--primary-light-red {
  background-color: var(--emu-semantic-colors-primary-red-200);
}

.bg--primary-dark-red {
  background-color: var(--emu-semantic-colors-primary-red-100);
}

.bg--secondary-light-blue {
  background-color: var(--emu-semantic-colors-secondary-blue-100);
}

.bg--secondary-dark-blue {
  background-color: var(--emu-semantic-colors-secondary-blue-200);
}

.tablet-view {
  display: none;

  @include mq('medium') {
    display: block;
  }
  @include mq('large') {
    display: none;
  }
}

.title--large {
  .cmp-title__text {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 3.6;
  }
}

.text--large {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  letter-spacing: 1.4px;
}

.text--small {
  font-size: 10px;
  line-height: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: 0.75px;
}
