.separator {
  .aaaem-separator {
    width: 94%;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}

@include mq('medium') {
  .separator {
    .aaaem-separator {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 30px;
    }
  }
}
