.emu-gallery {
  border-top: none;
  border-right: none;
  border-left: none;

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    padding-top: var(--emu-component-containers-gallery-padding-top-wide);
    padding-right: var(--emu-component-containers-gallery-padding-right-wide);
    padding-bottom: var(--emu-component-containers-gallery-padding-bottom-wide);
    padding-left: var(--emu-component-containers-gallery-padding-left-wide);
    margin-top: var(--emu-component-containers-gallery-margin-top-wide);
    margin-right: var(--emu-component-containers-gallery-margin-right-wide);
    margin-bottom: var(--emu-component-containers-gallery-margin-bottom-wide);
    margin-left: var(--emu-component-containers-gallery-margin-left-wide);
  }

  &__preview-item {
    border: none;
  }

  .gallery__stage-item {
    &-wrapper {
      @include mq('medium') {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .embed {
          flex: 1.5;
        }

        .container {
          flex: 1;
        }
      }
    }

    &-text-wrapper {
      border-top: var(--emu-semantic-border-width-thin) solid
        var(--emu-semantic-colors-primary-red-200);
      padding-top: 40px;
      padding-left: 37px;
      position: relative;

      &::after {
        content: '';
        height: 145px;
        width: 155px; // width and height are needed as adding background image does not give the content
        position: absolute;
        top: 10px;
        left: 10px;
        background-image: url('../../assets/images/quote.png');
        background-repeat: no-repeat;
        background-size: contain;
      }

      @include mq('medium') {
        padding-top: 20px;
        position: relative;
        left: 28px;
        max-width: 87%;
        &::after {
          top: 0;
          left: -30px;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-top: 40px;
        left: 60px;
        padding-left: 20px;

        &::after {
          left: -40px;
          top: 20px;
        }
      }
    }

    &-text {
      font-size: 17px;
      letter-spacing: 1.275px;
      line-height: 36px;
      padding-right: 25px;
      color: var(--emu-semantic-colors-secondary-dark-grey-400);

      @include mq('medium') {
        font-size: 20px;
        line-height: 24px;
        padding-right: 23px;
        padding-bottom: 25px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 2.1px;
        padding-bottom: 5px;
      }
    }

    &-subtext {
      font-size: 16px;
      letter-spacing: 0.2px;
      position: relative;

      p {
        text-align: right;
      }

      &::before {
        content: '';
        background-color: var(--emu-semantic-colors-primary-red-200);
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        max-width: 160px;

        @include mq('medium') {
          max-width: 110px;
        }

        @include mq('992px') {
          max-width: 160px;
        }
      }
    }
  }

  .gallery__preview-item {
    &-wrapper {
      display: flex;
      flex-direction: row;
      background-color: var(--emu-semantic-colors-secondary-light-grey-200);
      // margin-bottom: 20px;

      .image {
        padding-right: 10px;
        padding-left: 10px;
        max-width: 130px;
      }
    }

    &-text p {
      letter-spacing: 0.65px;
      padding-bottom: 20px;
      font-size: 11px;
      line-height: 15.4px;
      color: var(--emu-semantic-colors-secondary-dark-grey-200);

      @include mq('medium') {
        font-size: 9px;
        line-height: 12px;
        padding-right: var(--emu-semantic-spacing-none);
        padding-left: var(--emu-semantic-spacing-none);
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        font-size: 11px;
        line-height: 15.4px;
        padding-top: 5px;
        padding-right: 5px;
        padding-bottom: var(--emu-semantic-spacing-vertical);
      }
    }

    &-subtext {
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.75px;
      color: var(--emu-semantic-colors-secondary-dark-grey-200);
      display: flex;
      align-items: flex-end;
      gap: 8px;

      &::before {
        content: '';
        background-color: var(--emu-semantic-colors-primary-red-200);
        display: block;
        height: 1px;
        width: 92px; // widths are needed, this is a simple separator
        position: relative;
        top: -4px;

        @include mq('medium') {
          width: 40px; // widths are needed, this is a simple separator
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          width: 92px; // widths are needed, this is a simple separator
        }
      }
    }
  }
}
