.aaaem-teaser {
  .content-container {
    @include mq('medium') {
      position: absolute;
      top: 0;
    }

    .disclaimer {
      p {
        margin: var(--emu-common-spacing-none);
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        font-family: var(--emu-semantic-font-families-mono);
        line-height: normal;
        letter-spacing: 0.75px;
      }
    }

    .aaaem-teaser {
      &__title {
        max-width: 94%;
        margin-bottom: 10px;
        letter-spacing: normal;
        line-height: normal;
        color: var(--emu-semantic-colors-secondary-dark-grey-100);

        @include mq('medium') {
          letter-spacing: 3px;
          color: var(--emu-semantic-colors-secondary-light-grey-400);
        }
      }

      &__action-link {
        text-decoration: none;
        font-size: var(--emu-semantic-font-sizes-narrow-large);
        letter-spacing: 2px;
        padding-top: 12px;
        padding-right: 30px;
        padding-bottom: 12px;
        padding-left: 30px;
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-red-200);
        &:hover {
          background-color: var(--emu-semantic-colors-primary-red-300);
          span {
            color: var(--emu-semantic-colors-secondary-light-grey-600);
          }
        }

        @include mq('medium') {
          padding-top: 9px;
          padding-right: 18px;
          padding-bottom: 9px;
          padding-left: 18px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          padding-top: 12px;
          padding-right: 30px;
          padding-bottom: 12px;
          padding-left: 30px;
        }
      }
    }

    .cmp-teaser__action-link-text {
      color: var(--emu-semantic-colors-primary-red-200);
      text-decoration: none;
      @include mq('medium') {
        color: var(--emu-semantic-colors-secondary-light-grey-600);
      }
    }
    .cmp-teaser__description {
      margin-bottom: 10px;
      color: var(--emu-semantic-colors-secondary-dark-grey-100);
      p {
        letter-spacing: 0.9px;
        line-height: 20px;
        margin-right: -15px;
      }
      @include mq('medium') {
        color: var(--emu-semantic-colors-secondary-light-grey-400);
      }
    }
  }
}
