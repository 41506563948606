#about {
  //tabs component styles.
  .tabs--about-kyb {
    position: relative;
    margin: var(--emu-common-spacing-none);
    .aaaem-container {
      padding: var(--emu-common-spacing-none);
    }
    .aaaem-tabs__tablist {
      position: absolute;
      bottom: 11px;
      display: flex;
      justify-content: center;
      width: 100%;
      .aaaem-tabs__tab {
        margin-top: var(--emu-common-spacing-none);
        margin-right: 1px;
        margin-bottom: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);
        a {
          white-space: nowrap;
          height: 30px;
          background: var(--emu-semantic-colors-secondary-dark-grey-400);
          border-bottom: var(--emu-common-spacing-none);
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 7px;
          letter-spacing: 0px;
          font-family: var(--emu-semantic-font-families-mono);
          color: var(--emu-common-colors-white);
          width: 95px;
        }
        a[aria-selected='true'] {
          background-color: var(--emu-semantic-colors-primary-red-100);
        }
      }
    }
  }

  //heading about kyb page - ABOUT KYBELLA®
  .heading--about-kyb {
    padding-bottom: 10px;
    .title {
      .aaaem-title {
        margin-top: 20px;
        h2 {
          font-size: 27px;
          letter-spacing: 4px;
          line-height: 30px;
        }
      }
    }
    .text {
      margin-top: 10px;
      margin-bottom: 10px;
      .aaaem-text {
        width: 80%;
        margin: auto;
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          color: var(--emu-semantic-colors-secondary-dark-grey-200);
        }
      }
    }
  }

  // About kybella box
  .about--kybella-box {
    padding-top: 50px;
    padding-right: 5px;
    padding-bottom: 25px;
    padding-left: 35px;

    &-image {
      &-desktop {
        @include mq('medium') {
          display: none;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          display: block;
        }
      }

      &-tablet {
        display: none;

        @include mq('medium') {
          display: block;
          padding-top: 25px;
          height: 339px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: left;
        }
      }
    }
    &-top,
    &-bottom {
      > .aem-Grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    &-top {
      width: 100%;
      .cmp-title__text {
        margin: var(--emu-common-spacing-none);
      }
      ul {
        padding-left: 20px;
        li {
          color: inherit;
          line-height: 22px;
          padding-right: 12%;
          padding-bottom: 6px;
          letter-spacing: 0.9px;
        }
      }

      .button {
        @include mq('medium') {
          margin-bottom: var(--emu-semantic-spacing-vertical);
        }
      }
    }
    &-bottom {
      .aaaem-text {
        p {
          font-family: var(--emu-semantic-font-families-mono);
          color: var(--emu-semantic-colors-secondary-light-grey-400);
          margin: var(--emu-common-spacing-none);
          letter-spacing: 0.9px;
          padding-top: var(--emu-semantic-spacing-horizontal);
          padding-right: var(--emu-semantic-spacing-horizontal);
        }
      }
    }

    @include mq('medium') {
      &-top,
      &-bottom {
        > .aem-Grid {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }
      }
      &-top {
        border-bottom: 1px solid var(--emu-common-colors-white);
      }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      &-image {
        &-desktop {
          display: none;
        }
        &-tablet {
          display: block;
          padding-top: 25px;
        }
      }
      &-top {
        width: 100%;
        ul {
          padding-left: var(--emu-semantic-spacing-horizontal);
          li {
            padding-right: var(--emu-common-spacing-none);
            @media screen and (min-width: 768px) and (max-width: 800px) {
              padding-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      padding-top: 30px;
      padding-right: 10px;
      padding-bottom: 30px;
      padding-left: 20px;

      &-image {
        &-desktop {
          display: block;
          margin-top: 35px;
          border-bottom: 1px solid var(--emu-common-colors-white);
        }
        &-tablet {
          display: none;
        }
      }
      &-top {
        width: 100%;
        .cmp-title__text {
          font-size: 18px;
        }
        ul {
          li {
            padding-right: 12%;
            padding-bottom: 10px;
          }
        }
      }
      &-bottom {
        padding-top: 10px;
      }
    }
  }

  .allure--container {
    &-logo {
      width: 140px;
      margin-left: auto;
      margin-right: auto;

      @include mq('medium') {
        width: 160px;
      }
    }
  }

  // add this class to video component container
  .video-container--about-kyb {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    margin-top: 5px;
    .embed {
      width: 100%;
    }
  }

  .how--kyb-works {
    padding-top: 30px;
    > .aem-Grid {
      display: flex;
      flex-direction: column-reverse;
    }
    &__content-container {
      width: 100%;
      .aaaem-text p {
        color: var(--emu-semantic-colors-secondary-dark-grey-200);
        padding-right: 10%;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }
  }

  // add class to top parent container - WHAT TO EXPECT WITH KYBELLA® text container

  .parent-container--expect-with-kyb {
    margin-top: 2px;
  }

  .expect {
    &-title {
      .cmp-title__text {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
    &-paragraph {
      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        letter-spacing: 1px;
        width: 80%;
        margin: auto;
        line-height: var(--emu-semantic-line-heights-narrow-xl);
      }
    }
    @include mq('medium') {
      &-title {
        .cmp-title__text {
          margin-bottom: 10px;
        }
      }
      &-paragraph {
        p {
          width: 88%;
        }
      }
    }
    @include mq(992px) {
      &-title {
        .cmp-title__text {
          margin: var(--emu-common-spacing-none);
          padding-top: 35px;
          padding-bottom: 25px;
        }
      }
      &-paragraph {
        p {
          width: 80%;
        }
      }
    }
  }

  .kybella-administered {
    &__container {
      > .aem-Grid {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &__image-container {
      .aaaem-image {
        width: 450px;
        max-width: 100%;
      }
    }
    &__content {
      margin-left: var(--emu-semantic-spacing-horizontal);
      margin-bottom: 10px;

      .aaaem-title {
        padding-top: 30px;
      }

      .text {
        width: 100%;
        .aaaem-text:not(.title--medium) {
          padding-top: var(--emu-semantic-spacing-horizontal);
          padding-right: 25px;
          p {
            letter-spacing: 0.9px;
            color: var(--emu-semantic-colors-secondary-dark-grey-400);
          }
        }
      }
    }
  }

  // add class to tabs container - HOW MANY KYBELLA® TREATMENT
  .tabs-container--kyb-treatment-session {
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    &__first-sec {
      > .aem-Grid {
        display: flex;
        flex-direction: column-reverse;
      }
      .container {
        width: 100%;
        margin-left: var(--emu-common-spacing-none);
      }
      .tabs {
        width: 100%;
        .aaaem-tabs__tablist {
          justify-content: flex-start;
          margin-left: var(--emu-semantic-spacing-horizontal);
        }
      }
      .text {
        .aaaem-text {
          margin-top: 20px;
          margin-bottom: var(--emu-semantic-spacing-horizontal);
          p {
            letter-spacing: 1px;
          }
        }
      }
    }
    &__last-sec {
      > .aem-Grid {
        display: flex;
        flex-direction: column;
        &:nth-child(2) {
          .text {
            width: 100%;
            margin-left: var(--emu-semantic-spacing-horizontal);
          }
        }
        .tab-text--small {
          p {
            font-size: 10px;
            font-family: var(--emu-semantic-font-families-mono);
            line-height: var(--emu-semantic-line-heights-narrow-xl);
            letter-spacing: 0.75px;
          }
        }
        .container {
          width: 100%;
          .aaaem-container {
            .title {
              .aaaem-title {
                margin-top: 25px;
                margin-bottom: 10px;
              }
            }
            .text {
              margin-left: var(--emu-common-spacing-none);
              .aaaem-text {
                margin-bottom: 10px;
                p {
                  line-height: var(--emu-semantic-line-heights-narrow-xl);
                }

                &.tab-text--small {
                  p {
                    @include mq('medium') {
                      line-height: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .tab-bottom-text--discription-long {
      display: none;
    }
    .tab-bottom-text--discription-sort {
      display: block;
    }
  }

  .time-recover {
    &-container {
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
      .cmp-title__text {
        letter-spacing: 3px;
      }
      .aaaem-text p {
        margin: var(--emu-common-spacing-none);
        padding-top: var(--emu-semantic-spacing-vertical);
        padding-right: 25px;
        letter-spacing: 0.9px;
        color: var(--emu-semantic-colors-secondary-dark-grey-100);
      }
    }
  }

  .kybella-side-effects {
    &__container {
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
      display: flex;
      flex-direction: column;
    }
    &__serious {
      margin-bottom: var(--emu-semantic-spacing-vertical);
      .cmp-title__text {
        letter-spacing: 3px;
      }
      .cmp-text {
        p {
          margin-top: 20px;
          b {
            color: var(--emu-common-colors-black);
          }
        }
        ul {
          padding-left: var(--emu-semantic-spacing-horizontal);
          padding-right: var(--emu-semantic-spacing-horizontal);
          li {
            letter-spacing: 0.9px;
            padding-top: 5px;
          }
        }
      }
    }
    &__common {
      .aem-Grid {
        .title {
          .aaaem-title {
            margin-bottom: 10px;
            margin-top: 25px;
            h2 {
              font-size: var(--emu-semantic-font-sizes-narrow-xl);
              line-height: var(--emu-semantic-line-heights-narrow-xl);
              letter-spacing: 2.1px;
            }
          }
        }
        .text {
          .aaaem-text {
            margin-top: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .about-kybella-note {
    &-container {
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
      padding-bottom: 20px;
      .cmp-title__text {
        letter-spacing: 3px;
        margin-bottom: var(--emu-semantic-spacing-vertical);
      }
      .cmp-text {
        p {
          letter-spacing: 0.9px;
          margin-bottom: var(--emu-semantic-spacing-horizontal);
        }
        ul {
          padding-left: 25px;
          margin: var(--emu-common-spacing-none);
          li {
            letter-spacing: 1px;
            font-size: var(--emu-semantic-font-sizes-narrow-xl);
            padding-top: 10px;
            line-height: var(--emu-semantic-line-heights-narrow-xl);
          }
        }
      }
    }
    @include mq('medium') {
      &-container {
        padding: var(--emu-common-spacing-none);
        padding-left: 30px;
        padding-right: var(--emu-semantic-spacing-horizontal);
        .cmp-text {
          ul {
            margin-top: 10px;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      &-container {
        padding: var(--emu-common-spacing-none);
        padding-left: 20px;
        padding-right: 20px;
        .cmp-title__text {
          letter-spacing: 3px;
        }
        .cmp-text {
          ul {
            margin-top: 10px;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }

  @include mq('medium') {
    .tabs--about-kyb {
      .aaaem-tabs__tablist {
        .aaaem-tabs__tab {
          a {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            width: 104px;
          }
        }
      }
    }
    .how--kyb-works {
      > .aem-Grid {
        flex-direction: row;
      }
      .how--kyb-works__image-container {
        width: 324px;
        .aaaem-image {
          margin-right: var(--emu-semantic-spacing-horizontal);
        }
      }
      .how--kyb-works__content-container {
        > .aem-Grid {
          margin-left: var(--emu-semantic-spacing-horizontal);
        }
      }
    }
    .kybella-administered {
      &__container {
        > .aem-Grid {
          flex-direction: row;
        }
      }
      &__content {
        padding-left: var(--emu-semantic-spacing-horizontal);
        .title {
          .aaaem-title {
            padding-top: var(--emu-common-spacing-none);
          }
        }
      }
    }
    .tabs-container--kyb-treatment-session {
      &__first-sec {
        > .aem-Grid {
          flex-direction: row;
          .tabs {
            padding-left: 10px;
            padding-right: 5px;
          }
          .container {
            .title {
              padding-left: 20px;
            }
            .text {
              .aaaem-text {
                padding-left: 20px;
                padding-right: 20px;
                margin-top: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
      &__last-sec {
        > .aem-Grid {
          flex-direction: row;
          align-items: center;
          margin-top: -20px;
          .container {
            padding-left: var(--emu-semantic-spacing-horizontal);
            padding-right: 5px;
          }
        }
      }
    }
    .time-recover {
      &-container {
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    .kybella-side-effects {
      &__container {
        flex-direction: row;
      }
      &__serious {
        padding-left: var(--emu-semantic-spacing-horizontal);
        .title {
          .aaaem-title {
            padding-right: var(--emu-semantic-spacing-horizontal);
          }
        }
        .cmp-text {
          p {
            margin-top: 20px;
            font-size: 16px;
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }
        }
      }
      &__common {
        padding-left: 20px;
        padding-right: 25px;
        .text {
          .aaaem-text {
            font-size: 16px;
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }
        }
      }
    }
    .about-kybella-note {
      &-container {
        .container {
          .cmp-text {
            width: 285px;
            padding-bottom: 20px;
          }
        }
      }
    }

    // highlighting corresponding nav item
    .emu-navigation__content-wrapper nav > ul > li:nth-child(3) {
      > span {
        color: var(--emu-semantic-colors-primary-red-100);
      }
    }
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    .tabs--about-kyb {
      .aaaem-tabs__tablist {
        .aaaem-tabs__tab {
          a {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            width: 126px;
          }
        }
      }
    }
    .heading--about-kyb {
      .text {
        .aaaem-text {
          width: 65%;
        }
      }
    }
    .how--kyb-works {
      .how--kyb-works__image-container {
        width: 435px;
        height: 237px;
      }
    }
    .kybella-administered {
      &__content {
        padding-left: 20px;
        padding-top: 35px;
      }
    }
    .tabs-container--kyb-treatment-session {
      &__first-sec {
        > .aem-Grid {
          .tabs {
            padding-left: 25px;
            padding-right: 45px;
          }
        }
      }
      &__last-sec {
        > .aem-Grid {
          margin-top: var(--emu-common-spacing-none);
          .container {
            padding-left: 25px;
            padding-right: 10px;
          }
          .tab-text--small {
            padding-left: var(--emu-semantic-spacing-horizontal);
          }
        }
      }
      .tab-bottom-text--discription-long {
        display: block;
      }
      .tab-bottom-text--discription-sort {
        display: none;
      }
    }
    .kybella-side-effects {
      &__serious {
        .cmp-text {
          p {
            font-size: var(--emu-semantic-font-sizes-wide-large);
          }
        }
      }
      &__common {
        .text {
          .aaaem-text {
            font-size: var(--emu-semantic-font-sizes-wide-large);
            line-height: var(--emu-semantic-line-heights-wide-large);
          }
        }
      }
    }
    .about-kybella-note {
      &-container {
        padding-left: 30px;
        padding-right: 30px;
        .container {
          .cmp-text {
            max-width: 405px;
            width: 100%;
          }
        }
      }
    }
  }
}
