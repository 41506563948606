.tabs {
  .aaaem-tabs__tablist {
    .aaaem-tabs__tab {
      line-height: 12px;
    }
  }
}

@include mq('medium') {
  .tabs {
    .aaaem-tabs__tablist {
      .aaaem-tabs__tab {
        margin-left: 27px;
        margin-right: 27px;
      }
    }
  }
}
