[data-component='container-isi-v2'] {
  &.js-content-shown {
    .isi-container {
      opacity: 0;
      transform: none;
      pointer-events: none;
    }
  }

  .isi-container {
    background-color: rgba(255, 255, 255, 0.9);
    transition: opacity 1s;
    @include mq('medium') {
      background-color: var(
        --emu-component-containers-isi-header-color-background-light
      );
    }
  }

  .aaaem-isi-container {
    bottom: 55px;

    &__banner-content {
      h3,
      p {
        font-family: var(--emu-semantic-font-families-mono);
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        letter-spacing: 0;
        line-height: 19px;
        b {
          font-weight: var(--emu-semantic-font-weight-bold);
        }
      }

      h3 {
        width: 80%;
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: 10;
        font-weight: var(--emu-semantic-font-weight-bold);
        line-height: 1;
        color: var(--emu-semantic-colors-secondary-dark-grey-700);
      }

      p {
        margin-top: 5px;
      }
    }

    &__button-wrapper {
      position: absolute;
      top: 0;
      right: var(--emu-semantic-spacing-horizontal);
      .aaaem-button {
        padding-top: var(--emu-common-spacing-xs);
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-xs);
        padding-left: var(--emu-common-spacing-small);
        background-color: var(--emu-semantic-colors-primary-red-100);
        color: var(--emu-common-colors-white);
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        font-family: var(--emu-semantic-font-families-mono);
        letter-spacing: 1px;

        &::after {
          content: '+';
          margin-left: 10px;
          font-family: var(--emu-semantic-font-families-body);
          font-size: 20px;
        }
      }
    }

    &__isi-content {
      position: relative;
      background-color: var(--emu-semantic-colors-secondary-light-grey-200);
      padding-bottom: 30px;

      .button {
        position: absolute;
        top: 0;
        right: 0;

        .aaaem-button {
          text-decoration: none;
          padding-top: var(--emu-common-spacing-xs);
          padding-right: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-xs);
          padding-left: var(--emu-common-spacing-small);
          background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
          color: var(--emu-common-colors-white);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          font-family: var(--emu-semantic-font-families-mono);
          display: block;
          letter-spacing: 1px;

          &::after {
            content: '—';
            font-size: 18px;
          }
        }
      }

      .aaaem-text {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
        font-family: var(--emu-semantic-font-families-mono);
        padding-top: var(--emu-semantic-spacing-horizontal);

        h3 {
          font-weight: var(--emu-semantic-font-weight-bold);
          font-family: inherit;
          width: 70%;
          color: var(--emu-semantic-colors-secondary-dark-grey-700);
        }

        p {
          letter-spacing: 0;
          font-family: inherit;
          a {
            text-decoration: none;
            color: var(--emu-semantic-colors-secondary-blue-200);
          }
          b {
            font-weight: var(--emu-semantic-font-weight-bold);
          }
        }

        ul {
          padding-left: var(--emu-semantic-spacing-horizontal);
          li {
            letter-spacing: 0;
          }
        }
      }
    }
  }
  @include mq('medium') {
    .aaaem-isi-container {
      bottom: 0;
      background-color: var(--emu-semantic-colors-secondary-light-grey-200);

      &__button-wrapper {
        .aaaem-button {
          background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
        }
      }

      .aaaem-isi-banner {
        position: relative;
        max-width: var(--emu-semantic-sizing-breakpoints-medium);
        margin: auto;
      }

      &__isi-content {
        div {
          max-width: var(--emu-semantic-sizing-breakpoints-medium);
          margin: auto;
          position: relative;
          padding-top: var(--emu-semantic-spacing-horizontal);
          padding-right: var(--emu-common-spacing-none);
          padding-bottom: var(--emu-semantic-spacing-horizontal);
          padding-left: var(--emu-common-spacing-none);
          .button {
            right: var(--emu-common-spacing-none);
            padding: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
  @include mq('large') {
    .aaaem-isi-container {
      .aaaem-isi-banner {
        max-width: var(--emu-semantic-sizing-breakpoints-large);
        margin: auto;
      }
      &__isi-content {
        div {
          max-width: var(--emu-semantic-sizing-breakpoints-large);
          margin: auto;
          .button {
            right: 0;
          }
        }
      }
    }
  }
}

.cmp-experiencefragment--container-isi-v2 {
  margin-top: 10px;
}
