// from https://api.allerganfadadminv3.com/client/css/kybella.css
body.modal_on#kyb-fap-page .navbar-default,
body.modal_on#kyb-fap-page #ISIFloating {
  z-index: var(--emu-common-other-z-index-base);
}

body.modal_on#kyb-fap-page #locator_root {
  z-index: inherit;
}

#kyb-fap-page {
  .kyb-fap {
    display: none;
  }

  .cmp-container-header {
    border-bottom: none;
  }

  #locator_root {
    max-width: var(--emu-semantic-sizing-breakpoints-x-large);
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    #locator_root {
      margin-top: 170px;
    }
  }

  #locator_root
    .provider-search__search-form
    .provider-search__search-form--outer-input {
    background-color: var(--emu-semantic-colors-secondary-light-grey-1100);
  }

  #locator_root .provider-search__search-form .button span.text {
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    #locator_root .search-sort-container {
      -ms-grid-columns: 0.7fr 10px 1fr;
      grid-template-columns: 0.7fr 10px 1fr;
    }
  }

  #locator_root #sortList ul li label.cb-container {
    font-size: 12px;
    padding-left: 20px;
    font-weight: normal;
  }

  #locator_root
    .consultation-request-form__inline-form__form
    button.button__theme-dark {
    background-color: transparent;
    border: var(--emu-semantic-border-width-thin) solid
      var(--emu-semantic-colors-secondary-dark-grey-700);
  }

  #locator_root
    .consultation-request-form__inline-form__form
    button.button__theme-dark
    span.text {
    margin-top: var(--emu-semantic-sizing-none);
    color: var(--emu-semantic-colors-secondary-dark-grey-700);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    #locator_root .providers-results-page .provider-search__search-results {
      margin-left: 20px;
    }
  }

  @media (min-width: 89.9875em) {
    #locator_root
      .providers-results-page
      .provider-search__search-form
      .location-input {
      padding-left: 42px;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #locator_root #sort_slider input[type='range'] {
      top: -17px;
    }

    #locator_root
      .provider-profile-page
      .provider-profile-content-container
      #treatment-list
      ul
      li {
      font-size: 0.8em;
      height: 3.5em;
      margin-bottom: 10px;
    }
  }

  // overrides to match design
  #locator_root {
    .modal_isi .isi_content {
      h2,
      p {
        color: #222; // no variable, used only once
      }
    }

    #filters,
    .lightbox-background .fap-modal {
      font-size: 14px;
      .sub-text {
        font-family: var(--emu-semantic-font-families-mono);
      }

      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 10px;
      }
    }

    .provider-search-filter-modal__content__form__fields__field
      .range_background {
      line-height: 1.65;
    }

    .fap-modal {
      #hours-container {
        li span {
          color: var(--emu-semantic-colors-secondary-dark-grey-200);
        }
      }
    }

    .legal-disclosure--dark a {
      color: #0000ee; // no variable, used in single place
    }

    #sortList ul li label.cb-container {
      color: var(--emu-semantic-colors-secondary-dark-grey-200);
    }
  }
}
