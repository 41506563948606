header.experiencefragment {
  height: 95px; // placeholder space for the header, so that the content starts after the header, and not beneath the header
  @include mq('medium') {
    height: 170px;
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    height: 140px;
  }
}

.has-header-fixed {
  .header {
    @include mq('medium') {
      .cmp-container-header {
        top: 0;
        border: none;
      }

      &__eyebrow {
        transform: translateY(-100%);
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-semantic-colors-secondary-light-grey-600);

  sup {
    font-size: 10px;
    line-height: 8px;
  }

  &__eyebrow {
    @include mq('medium') {
      background-color: #e6e6e6; // no variable for this one
      z-index: 501;
      position: fixed;
      top: 0;
      width: 100%;

      &-main {
        display: flex;
        justify-content: center;

        > .text:last-child {
          .header__eyebrow-link {
            border: none;
          }
        }
      }

      &-link {
        font-size: 8px;
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        color: #525154; // no variable
        border-right: var(--emu-semantic-border-width-thin) solid
          var(--emu-semantic-colors-secondary-light-grey-600);

        @include mq(1012px) {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
        }

        &.aaaem-text {
          p {
            font-family: var(--emu-common-font-families-sans);
            letter-spacing: normal;
          }

          a {
            padding-top: 5px;
            padding-right: 3px;
            padding-left: 3px;
            display: block;
            text-decoration: none;

            &:hover {
              background-color: #eeeeee; // no variable
            }

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              padding-top: 3px;
              padding-right: var(--emu-semantic-spacing-horizontal);
              padding-bottom: var(--emu-semantic-spacing-none);
              padding-left: var(--emu-semantic-spacing-horizontal);
            }
          }

          &.header__eyebrow-link--active {
            a {
              color: var(--emu-semantic-colors-secondary-light-grey-600);
              &:hover {
                color: var(--emu-semantic-colors-secondary-light-grey-600);
                background-color: var(
                  --emu-semantic-colors-secondary-dark-grey-400
                );
              }
            }
          }
        }

        .emphasis {
          display: block;
          font-size: 11px;
          line-height: 1;
          font-weight: var(--emu-semantic-font-weight-bold);

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
          }
        }

        &--active {
          color: var(--emu-semantic-colors-secondary-dark-grey-600);
          a {
            background-color: var(
              --emu-semantic-colors-secondary-dark-grey-400
            );
          }
        }
      }
    }
  }

  .cmp-container-header {
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-right: var(--emu-semantic-spacing-horizontal);
    padding-bottom: 10px;
    padding-left: var(--emu-semantic-spacing-horizontal);

    @include mq('medium') {
      position: relative;
      top: 36px;
      padding-top: 10px;
      max-width: var(--emu-semantic-sizing-breakpoints-medium);
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: auto;
      margin-right: auto;
      background-color: var(--emu-semantic-colors-secondary-light-grey-600);
      padding-bottom: 5px;
      border-bottom: var(--emu-semantic-border-width-thin) solid
        var(--emu-semantic-colors-secondary-light-grey-800);

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        background-color: inherit;
        transform: translateX(-50%);
        z-index: var(--emu-common-other-z-index-behind);
        width: 100vw;
        height: 100%;
      }
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      top: 39px;
      max-width: 990px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: var(--emu-semantic-spacing-vertical);
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
    }

    .header {
      // logo
      &__logo {
        min-width: 182px;
        max-width: 50%;
        img,
        a {
          width: 100%;
          display: block;
          max-width: 182px;
        }

        @include mq('medium') {
          min-width: 265px; // logo width for tablet and above

          img,
          a {
            max-width: none;
          }
        }
      }

      // nav
      &__global-navigation {
        display: none;
      }

      &__content {
        margin-left: auto;

        @include mq('medium') {
          margin-left: var(--emu-semantic-spacing-none);
          width: 100%;
        }

        // mobile eyebrow with very small text
        > .text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: #edeef0; // no variable

          @include mq('medium') {
            background-color: var(--emu-semantic-colors-none);
            top: -3px;
          }

          .aaaem-text {
            color: #7b7b7b; // no variable
            font-size: 7px;
            line-height: 10px;

            @include mq('medium') {
              font-size: 10px;
              padding-top: 8px;
              padding-bottom: 5px;
              font-family: 'nimbus-sans-condensed', sans-serif; // no variable, being used only in few places, and does not have a emu variable to assign to
              color: var(--emu-semantic-colors-secondary-dark-grey-400);
            }

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              font-size: var(--emu-semantic-font-sizes-wide-large);
            }
          }

          ul {
            display: flex;
            justify-content: center;
            list-style: none;

            @include mq('medium') {
              width: 100%;
              justify-content: flex-end;
              max-width: 768px;
              margin-top: var(--emu-semantic-spacing-none);
              margin-right: auto;
              margin-bottom: var(--emu-semantic-spacing-none);
              margin-left: auto;
              padding-left: var(--emu-semantic-spacing-horizontal);
              padding-right: var(--emu-semantic-spacing-horizontal);
            }

            @include mq($emu-semantic-sizing-breakpoints-x-large) {
              max-width: var(--emu-semantic-sizing-breakpoints-large);
            }

            li {
              letter-spacing: normal;
              &:not(:last-child) {
                padding-right: 4px;
                margin-right: 4px;
                border-right: var(--emu-semantic-border-width-thin) solid
                  #d3d3d3; // no variable
              }

              &:nth-child(3) {
                display: none;

                @include mq('medium') {
                  display: block;
                }
              }

              a {
                text-decoration: none;
                letter-spacing: 0;
                @include mq('medium') {
                  text-transform: uppercase;
                  letter-spacing: normal;
                  &:hover {
                    color: #7b7b7b; // no variable
                  }
                }
              }
            }
          }
        }

        // mobile trigger
        .button {
          .aaaem-button {
            display: block;
            width: 34px; // button does not have any content. width and height is needed
            height: 24px;
            padding: var(--emu-semantic-spacing-none);
            position: relative;

            &:hover,
            &:active,
            &:focus {
              background-color: var(--emu-semantic-colors-none);
              outline: none;
              border-color: var(--emu-semantic-colors-none);
            }

            span:not(.chevron) {
              position: absolute;
              top: 10px;
              left: 0;
              height: 3px; // height is needed for hamburger line
              background-color: var(--emu-semantic-colors-secondary-blue-100);
              width: 100%;
              display: block;

              &::after,
              &::before {
                content: '';
                display: block;
                width: 100%;
                height: 3px;
                background-color: var(
                  --emu-semantic-colors-secondary-blue-100
                );
                position: absolute;
                left: 0;
              }

              &::before {
                top: -7px;
              }

              &::after {
                top: 7px;
              }
            }

            &.js-toggle-on {
              span:not(.chevron) {
                background-color: transparent;

                &::after {
                  transform: rotate(45deg);
                  top: 4px;
                  left: 4px;
                }

                &::before {
                  transform: rotate(-45deg);
                  transform-origin: right;
                }
              }
            }
          }

          @include mq('medium') {
            display: none;
          }
        }
      }
    }
  }
}
