.aaaem-card {
  header {
    display: none;
  }

  &__body {
    .title {
      .cmp-title__text {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 2.5px;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 10px;
      }
    }
  }

  footer {
    text-align: left;
    padding: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-semantic-spacing-vertical);
    margin-left: 20px;
    margin-right: 20px;

    .aaaem-container {
      display: flex;
      flex-direction: column;
      padding: var(--emu-common-spacing-none);

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .cmp-text p {
      letter-spacing: 1px;
      margin-bottom: 10px;

      @include mq('medium') {
        margin-bottom: 30px;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        margin-bottom: 10px;
      }
    }
  }
}

.kyb-card-container {
  > .aem-Grid {
    display: flex;
    flex-direction: column;

    @include mq(medium) {
      flex-direction: row;
      .card {
        flex: 1;

        .aaaem-card {
          margin-right: 3px;
        }

        &:last-child {
          .aaaem-card {
            margin-right: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
