.kyb-footer {
  &__main-wrapper {
    &.aaaem-container {
      padding-top: 25px;
      padding-bottom: var(--emu-semantic-spacing-vertical);
    }
  }

  &__top-section {
    border-bottom: var(--emu-semantic-border-width-thin) solid
      var(--emu-semantic-colors-secondary-light-grey-800);
    display: flex;
    justify-content: space-between;

    .aaaem-container {
      .text {
        .aaaem-text {
          ul {
            margin: var(--emu-common-spacing-none);

            li {
              list-style: none;
              margin-bottom: 20px;

              .emphasis {
                display: flex;
                align-items: center;
                gap: 6px;

                img {
                  height: 12px;
                  width: auto;
                }

                a {
                  padding-right: var(--emu-common-spacing-none);
                }
              }

              a {
                text-decoration: none;
                color: var(--emu-common-colors-white);
                font-family: 'nimbus-sans-condensed', sans-serif;
                line-height: 10px;
                display: block;

                &:hover {
                  background-color: var(
                    --emu-semantic-colors-secondary-light-grey-500
                  );
                  color: var(--emu-semantic-colors-secondary-dark-grey-500);
                }
              }
            }
          }

          p {
            margin-top: 50px;

            a {
              text-decoration: none;
              margin-right: 25px;

              img {
                max-width: 31px;
              }
            }
          }
        }
      }
    }
  }

  &__links-left-section {
    max-width: 182px;
  }

  &__bottom-section {
    .image {
      margin-top: 20px;
      margin-bottom: 20px;
      padding-right: var(--emu-semantic-spacing-horizontal);

      .aaaem-image {
        max-width: 111px;

        img {
          width: 100%;
        }
      }
    }

    .text {
      .aaaem-text {
        letter-spacing: 0px;
        color: var(--emu-common-colors-white);

        p {
          margin-bottom: 25px;
          font-family: 'nimbus-sans-condensed', sans-serif;
        }
      }
    }
  }
}

@include mq('medium') {
  .kyb-footer {
    &__main-wrapper {
      &.aaaem-container {
        padding-bottom: var(--emu-common-spacing-none);
      }
    }

    &__top-section {
      flex-direction: column;

      .aaaem-container {
        .text {
          .aaaem-text {
            ul {
              li {
                margin-bottom: var(--emu-common-spacing-none);

                a {
                  line-height: 1.25;
                }
              }
            }

            p {
              margin-top: 20px;
              text-align: center;

              a {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }

    &__links-left-section {
      width: 100%;
      padding-left: var(--emu-semantic-spacing-horizontal);
      gap: 70px;
    }

    &__links-left-section {
      .text {
        max-width: 300px;
      }
    }

    &__links-left-section,
    &__links-right-section {
      display: flex;

      .aaaem-container {
        gap: 70px;
      }

      .text {
        .aaaem-text {
          ul {
            li {
              a {
                padding-right: var(--emu-semantic-spacing-horizontal);
                padding-top: 10px;
                padding-bottom: 10px;
                width: fit-content;
              }
            }
          }
        }
      }
    }

    &__bottom-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        .aaaem-text {
          width: 90%;

          p {
            margin-top: 20px;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      .image {
        padding-left: var(--emu-semantic-spacing-horizontal);
      }
    }
  }
}

@include mq($emu-semantic-sizing-breakpoints-x-large) {
  .kyb-footer {
    &__bottom-section {
      .text {
        .aaaem-text {
          width: 100%;
        }
      }
    }
  }
}
