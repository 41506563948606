#before-after {
  // gallery tab component
  .tab-gallery--before-after {
    position: relative;
    .tns-outer {
      .tns-inner {
        height: auto !important; // height is being added as element style by tns.js. Important is intentional

        .aaaem-carousel__content {
          position: relative;
          .aaaem-carousel__item {
            .text {
              .aaaem-text {
                padding-top: var(--emu-semantic-spacing-vertical);
                padding-right: var(--emu-common-spacing-none);
                padding-bottom: 20px;
                padding-left: var(--emu-semantic-spacing-horizontal);

                h4 {
                  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
                  letter-spacing: 3px;
                }

                p {
                  letter-spacing: 1px;
                  margin-top: -10px;
                  line-height: var(--emu-semantic-line-heights-narrow-xl);
                }
              }
            }

            .tabs {
              .aaaem-tabs {
                margin: var(--emu-common-spacing-none);
                display: flex;
                flex-direction: column-reverse;

                &__tabpanels {
                  .aaaem-tabs__tabpanel {
                    border-bottom: var(--emu-semantic-border-width-thin)
                      solid var(--emu-common-colors-white);
                  }
                }

                &__tablist {
                  display: flex;
                  justify-content: center;
                  padding-top: 15vw; // necessary as the spacing have to be w.r.t the size of the image above the text
                  position: relative;

                  &::before {
                    content: 'ROTATION';
                    color: var(--emu-common-colors-white);
                    font-family: var(--emu-semantic-font-families-mono);
                    position: absolute;
                    bottom: 6vw; // necessary as the spacing have to be w.r.t the size of the image above the text
                    width: 100%;
                    display: flex;
                    justify-content: center;
                  }
                }
              }
            }

            .tab-gallery--bottom-sticky-text {
              width: 100%;
              display: flex;
              position: absolute;
              bottom: 13vw; // necessary as the spacing have to be w.r.t the size of the image above the text
              justify-content: center;

              .text {
                width: 28vw; // necessary as the spacing have to be w.r.t the size of the image above the text
                text-align: center;

                .aaaem-text {
                  padding: var(--emu-common-spacing-none);
                  font-size: 8px;
                  margin: auto;

                  p {
                    line-height: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .aaaem-carousel__indicators {
      padding-right: var(--emu-semantic-spacing-horizontal);
      padding-left: var(--emu-semantic-spacing-horizontal);
      margin-top: var(--emu-common-spacing-none);
      overflow-x: auto;
      display: flex;
      justify-content: flex-start;
      background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
      position: static;

      .aaaem-carousel__image-indicator {
        border: none;
        opacity: 0.5;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;

        &:hover {
          opacity: 1;
          .aaaem-carousel__image-indicator-name {
            background-color: var(--emu-semantic-colors-primary-red-200);
            padding-top: 2px;
            padding-bottom: 2px;
            display: block;
            position: absolute;
            bottom: var(--emu-common-spacing-none);
          }
        }

        img {
          min-width: 60px;
          min-height: 60px;
        }
      }

      .tns-nav-active {
        opacity: 1;
      }
    }
  }

  .text-bottom--tab-gallery {
    padding-bottom: 5px;

    .aem-Grid {
      display: flex;
      flex-direction: column;

      .container {
        width: 100%;

        .aaaem-container {
          .text {
            .aaaem-text {
              font-size: 10px;
              padding-right: var(--emu-semantic-spacing-horizontal);
              padding-left: var(--emu-semantic-spacing-horizontal);

              p {
                margin-bottom: 10px;
                font-family: var(--emu-semantic-font-families-mono);
                line-height: 14px;
                color: var(--emu-semantic-colors-secondary-dark-grey-600);
              }
            }
          }
        }
      }
    }
  }

  // REAL STORIES text container
  .text-container--real-stories {
    .title {
      .aaaem-title {
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxxl);
        letter-spacing: 3.6px;
        text-align: center;
        padding-top: 30px;
      }
    }

    .text {
      .aaaem-text {
        letter-spacing: 1px;
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xl);
        width: 79%;
        margin: auto;
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  // Add class to Gallery components container - HEAR WHAT PATIENTS
  .gallery-component--hear-what-patients {
    .title {
      width: 80%;
      margin: auto;
      .aaaem-title {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }

  // Add class to IN CLINICAL STUDIES (79%)  text container -
  .text-large--in-clinical-studies {
    padding-top: 10px;
    padding-bottom: 10px;

    &__left-sec {
      display: flex;
      align-items: flex-start;
      border-bottom: var(--emu-semantic-border-width-thin) solid
        var(--emu-semantic-colors-actions-primary-dark);
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);

      .text--large {
        font-size: 50px;
        line-height: 70px;
        margin-top: -15px;
      }

      .text--large-left {
        width: 160px;
        .text {
          .aaaem-text {
            p {
              text-align: center;
            }
          }
        }
      }

      .text--medium-left {
        .text {
          .aaaem-text {
            p {
              letter-spacing: 0px;
              color: var(--emu-semantic-colors-secondary-dark-grey-200);
              line-height: 22px;
            }
          }
        }
      }
    }

    &__right-sec {
      width: 100%;

      .text {
        .aaaem-text {
          margin-left: var(--emu-semantic-spacing-horizontal);
          margin-right: var(--emu-semantic-spacing-horizontal);
          padding-top: var(--emu-semantic-spacing-vertical);
          padding-bottom: 20px;

          p {
            letter-spacing: 0px;
          }
        }
      }
    }
  }

  @include mq('390px') {
    .tab-gallery--before-after {
      .tns-outer {
        .tns-inner {
          .aaaem-carousel__content {
            .aaaem-carousel__item {
              .tabs {
                .aaaem-tabs {
                  .aaaem-tabs__tablist {
                    padding-top: 12vw; // necessary as the spacing have to be w.r.t the size of the image above the text
                  }
                }
              }
              .tab-gallery--bottom-sticky-text {
                bottom: 12vw; // necessary as the spacing have to be w.r.t the size of the image above the text
                .text {
                  .aaaem-text {
                    line-height: var(--emu-semantic-line-heights-narrow-xl);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include mq('medium') {
    .tab-gallery--before-after {
      .tns-outer {
        .tns-inner {
          .aaaem-container {
            margin-top: 30px;
            margin-bottom: 50px;
            margin-left: 33px;
            border-bottom: 1px solid var(--emu-common-colors-white);
          }
          .aaaem-carousel__content {
            position: relative;

            .aaaem-carousel__item {
              .aaaem-container {
                display: flex;
                align-items: center;

                .text {
                  .aaaem-text {
                    padding: var(--emu-common-spacing-none);
                  }
                }

                .tabs {
                  .aaaem-tabs__tabpanels {
                    .aaaem-tabs__tabpanel {
                      border-bottom: var(
                        --emu-semantic-outline-width-unset
                      );

                      .image {
                        .aaaem-image {
                          img {
                            height: 167px;
                            margin-left: 13px;
                          }
                        }
                      }
                    }
                  }

                  .aaaem-tabs__tablist {
                    padding-top: var(--emu-common-spacing-none);
                    position: absolute;
                    bottom: 0px;
                    left: 28%;
                    &::before {
                      bottom: -3%;
                      left: -25%;
                      width: fit-content;
                    }
                  }
                }

                .tab-gallery--bottom-sticky-text {
                  bottom: -5%;
                  width: fit-content;
                  border-bottom: var(--emu-semantic-outline-width-unset);
                  left: 30%;

                  .text {
                    width: 154px;
                  }
                }
              }
            }
          }
        }
      }

      .aaaem-carousel__indicators {
        padding-left: 30px;
        justify-content: flex-start;
      }
    }

    .text-bottom--tab-gallery {
      margin-left: var(--emu-semantic-spacing-horizontal);
      margin-right: var(--emu-semantic-spacing-horizontal);
      padding-bottom: 20px;

      .aem-Grid {
        flex-direction: row;
        .aaaem-container {
          .aem-Grid {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .text-container--real-stories {
      .title {
        .aaaem-title {
          padding-top: 20px;
        }
      }
    }

    .text-large--in-clinical-studies {
      display: flex;

      &__left-sec {
        border-bottom: var(--emu-semantic-outline-width-unset);

        .text--medium-left {
          padding-right: 30px;
          border-right: var(--emu-semantic-border-width-thin) solid
            var(--emu-semantic-colors-actions-primary-dark);
        }

        .text--large {
          font-size: 75px;
          line-height: 105px;
          margin-top: -20px;
        }
      }

      &__right-sec {
        width: 250px;

        .text {
          .aaaem-text {
            padding-left: 10px;
            padding-right: var(--emu-semantic-spacing-horizontal);
          }
        }
      }
    }

    // highlighting corresponding nav item
    .emu-navigation__content-wrapper nav > ul > li:nth-child(5) {
      > span {
        color: var(--emu-semantic-colors-primary-red-100);
      }
    }
  }
  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    .tab-gallery--before-after {
      .tns-outer {
        .tns-inner {
          .aaaem-container {
            margin-top: 50px;
            margin-left: 55px;
          }

          .aaaem-carousel__content {
            .aaaem-carousel__item {
              .aaaem-container {
                .tabs {
                  .aaaem-tabs__tabpanels {
                    .aaaem-tabs__tabpanel {
                      .image {
                        .aaaem-image {
                          img {
                            height: 217px;
                            margin-left: 66px;
                          }
                        }
                      }
                    }
                  }

                  .aaaem-tabs__tablist {
                    left: 32%;
                  }
                }

                .tab-gallery--bottom-sticky-text {
                  bottom: -5%;
                  width: fit-content;
                  border-bottom: none;
                  left: 33%;
                  .text {
                    width: 190px;
                  }
                }
              }
            }
          }
        }
      }

      .aaaem-carousel__indicators {
        justify-content: center;
      }
    }

    .text-bottom--tab-gallery {
      margin-left: 45px;
      margin-right: 30px;
      padding-bottom: 30px;
    }

    .gallery-component--hear-what-patients {
      .title {
        width: 64%;
      }
    }

    .text-large--in-clinical-studies {
      &__left-sec {
        .text--medium-left {
          padding-left: 38px;
        }
        .text--large {
          font-size: 65px;
          line-height: 91px;
          margin-top: -20px;
        }
      }

      &__right-sec {
        width: 300px;
      }
    }
  }

  .cmp-container-header {
    border: none;
  }
}
