#about-smf {
  // first title and text
  .heading__about-smf {
    max-width: 86%;
    margin-left: auto;
    margin-right: auto;

    @include mq('medium') {
      max-width: calc(86% - 70px);
    }

    .title {
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .text {
      margin-bottom: 20px;
    }
  }

  // about smf - WHAT CAUSES SUBMENTAL FULLNESS? card (second section)
  .card__what-causes-smf {
    padding-bottom: var(--emu-semantic-spacing-horizontal);

    @include mq('medium') {
      padding-bottom: 5px;
    }

    .aaaem-title,
    .aaaem-text,
    .aaaem-container {
      max-width: 81%;
      margin-left: auto;
      margin-right: auto;
      float: none;
      clear: both;

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        max-width: none;
      }
    }

    .text-container__what-causes-smf.aaaem-text {
      max-width: none;
      padding: 0 var(--emu-semantic-spacing-horizontal);

      @include mq('medium') {
        max-width: 83.33%;
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        max-width: 66.67%;
      }
    }

    .text {
      .aaaem-text {
        margin-bottom: 20px;

        @include mq('medium') {
          margin-bottom: 35px;
        }
      }
    }

    .title {
      margin-top: 30px;
      margin-bottom: 30px;

      @include mq('medium') {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    // add this class to image container in card component
    .image-container__what-causes-smf {
      @include mq('medium') {
        &.aaaem-container {
          width: 650px;
          > .aem-Grid {
            flex-direction: row;
          }
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        &.aaaem-container {
          width: 100%;
          max-width: var(--emu-semantic-sizing-breakpoints-large);
          > .aem-Grid {
            justify-content: center;
          }
        }
      }

      > .aem-Grid {
        display: flex;
        flex-direction: column;
        align-items: center;

        > .container {
          max-width: 260px;
          width: 100%;

          @include mq($emu-semantic-sizing-breakpoints-x-large) {
            width: 250px;

            img {
              max-width: 218px;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        .aaaem-text {
          margin-bottom: 10px;
          margin-top: 10px;

          @include mq('medium') {
            margin-bottom: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  // third section BOTHERED BY SUBMENTAL FULLNESS?
  .third-sec__bothered-by-smf {
    padding-top: 0;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 25px;

    > .aem-Grid {
      > .title {
        .cmp-title__text {
          line-height: 28px;
          margin-top: 20px;
          margin-bottom: 10px;
          width: 60%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      > .text {
        margin-top: 10px;
        margin-bottom: 35px;
        .aaaem-text {
          width: 70%;
          margin: auto;
        }
      }

      .aaaem-text:not(.text--color-blue) {
        color: var(--emu-semantic-colors-secondary-dark-grey-200);
        letter-spacing: 0;
      }
    }

    .text--jumbo {
      font-size: 70px;
      line-height: 1;
    }

    // add this class to text percentage container
    .third-section__text-container {
      .third-section__text-top {
        border-bottom: 1px solid var(--emu-semantic-colors-primary-red-200);
        display: flex;
        align-items: center;
        gap: 5%;
        padding-bottom: 10px;
        margin-bottom: 35px;
      }

      .third-section__text-bottom {
        line-height: 22px;
      }
    }

    @include mq('medium') {
      > .aem-Grid {
        > .title {
          .cmp-title__text {
            width: 100%;
            margin-bottom: var(--emu-common-spacing-none);
          }
        }

        > .text {
          .aaaem-text {
            width: 44%;
          }
        }
      }

      .text--jumbo {
        font-size: 75px;
      }

      .third-section__text-container {
        overflow: hidden;
        clear: both;

        > .container {
          width: 54%;
          float: left;

          &:last-child {
            width: calc(46% - 20px);
          }
        }

        .third-section__text-top {
          border-bottom: none;
          margin-bottom: var(--emu-common-spacing-none);
          border-right: 1px solid var(--emu-semantic-colors-primary-red-200);
          gap: 10px;
          padding-right: 20px;
          margin-right: 20px;
        }
      }
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-left: 35px;
      padding-right: 35px;

      > .aem-Grid {
        > .text {
          .aaaem-text {
            width: 70%;
          }
        }
      }

      .third-section__text-container {
        > .container {
          width: 54%;
          float: left;

          &:last-child {
            width: calc(46% - 30px);
          }
        }

        .third-section__text-top {
          gap: 30px;
          padding-right: 30px;
          margin-right: 30px;
        }
      }
    }
  }

  // KYBELLA MAY HELP IMPROVE YOUR CHIN PROFILE (fourth section)
  .fourth-sec__kybella-may-help-smf {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid
      var(--emu-semantic-colors-secondary-light-grey-800);

    a {
      text-decoration: none;
    }

    > .aem-Grid {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: var(--emu-semantic-spacing-vertical);

      > .container {
        width: 100%;
        .aaaem-container {
          img {
            width: auto;
            height: auto;
            max-width: 100%;
          }

          .title {
            margin-top: 35px;
            margin-bottom: 10px;
          }

          > .text {
            margin-bottom: 20px;
            padding-left: 10px;
            .aaaem-text:not(.title) {
              color: var(--emu-semantic-colors-text-light);
            }
          }
        }
      }
    }

    @include mq('medium') {
      padding-top: 20px;
      padding-bottom: 10px;
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-right: var(--emu-semantic-spacing-horizontal);
      border-top: 2px solid var(--emu-semantic-colors-secondary-light-grey-600);

      > .aem-Grid {
        flex-direction: row;
        padding-bottom: var(--emu-common-spacing-none);

        > .container {
          width: 50%;
          padding-left: 30px;

          .aaaem-container {
            .title {
              margin-top: 20px;
              padding-left: var(--emu-common-spacing-none);
            }

            > .text {
              margin-bottom: 10px;
              padding-left: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 50px;
      padding-bottom: 35px;

      > .aem-Grid {
        > .container {
          &:first-child {
            padding-left: var(--emu-common-spacing-none);
          }
        }
      }
    }
  }
}
