#home {
  // Home Page Teaser Styling.
  .double-chin-teaser {
    .content-container {
      .disclaimer {
        position: absolute;
        top: -50px;

        @include mq('medium') {
          position: relative;
          top: 12px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          top: 0;
        }
      }

      @include mq('medium') {
        padding-top: 52px;
        padding-right: var(--emu-common-spacing-none);
        padding-bottom: 16px;
        padding-left: 36px;
        width: 60%;

        .cmp-teaser__description {
          color: var(--emu-semantic-colors-secondary-light-grey-400);
          max-width: 94%;
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-top: 52px;
        padding-right: 44px;
        padding-bottom: 36px;
        padding-left: 44px;
        width: 55%;

        .cmp-teaser__description {
          padding-right: var(--emu-common-spacing-none);
        }
      }
    }
  }

  .real-stories-teaser {
    .content-container {
      margin-top: 65px;

      .content {
        flex-wrap: nowrap;
      }

      .disclaimer {
        position: absolute;
        top: -70px;

        @include mq('475px') {
          top: -110px;
        }

        @include mq('medium') {
          position: relative;
          top: 35px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          top: 65px;
        }
      }

      @include mq('medium') {
        margin-top: var(--emu-common-spacing-none);
        padding-left: 36px;

        .content {
          flex-wrap: nowrap;
          .cmp-teaser__description {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-left: 44px;
        padding-top: var(--emu-semantic-spacing-horizontal);
        .content {
          .aaaem-teaser {
            &__title {
              margin-bottom: var(
                --emu-component-cards-teaser-title-margin-bottom-wide
              );
            }

            &__action-link:hover {
              background: var(--emu-semantic-colors-primary-red-300);
            }
          }

          .cmp-teaser__description {
            margin-bottom: 10px;
            p {
              margin-top: var(
                --emu-component-cards-teaser-description-margin-top-narrow
              );
            }
          }
        }
      }
    }
  }

  .alle-rewards-teaser {
    .content-container {
      padding: var(--emu-common-spacing-none);

      .content {
        padding: var(--emu-semantic-spacing-horizontal);
        text-align: left;

        .cmp-teaser__description,
        .aaaem-teaser__action-container {
          margin-left: var(--emu-common-spacing-none);
        }

        @include mq('medium') {
          padding: var(--emu-common-spacing-none);
          padding-bottom: 80px;

          .aaaem-teaser__action-container {
            margin-left: auto;
          }

          .cmp-teaser__description {
            display: none;
          }

          .aaaem-teaser__action-link {
            border-color: var(--emu-semantic-colors-secondary-light-grey-600);
            &:hover {
              background: var(--emu-semantic-colors-secondary-light-grey-700);
            }
          }
        }
      }
    }
  }

  // Home Page Cards Styling.
  .kyb-card-container {
    .card {
      > div,
      .aaaem-card {
        &__body {
          .title {
            .cmp-title__text {
              font-size: 16px;
              line-height: 22.4px;
              max-width: 65%;
            }
          }
        }

        .button {
          padding: var(--emu-common-spacing-none);
        }

        margin-bottom: 3px;

        @include mq(medium) {
          &__body {
            .title {
              .cmp-title__text {
                letter-spacing: 2.5px;
                max-width: 100%;
              }
            }
          }

          footer {
            padding-bottom: var(--emu-semantic-spacing-horizontal);
            .aaaem-container {
              flex-direction: column;
              align-items: flex-start;
            }
          }

          .button {
            position: relative;
            padding: var(--emu-common-spacing-none);
          }

          .aaaem-button {
            position: relative;
            left: 0px;
            bottom: 0px;
          }
          margin-bottom: var(--emu-common-spacing-none);
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          footer {
            .aaaem-container {
              flex-direction: row;
            }
          }
        }
      }
    }
  }

  .cmp-container-header {
    border: none;
  }
}
