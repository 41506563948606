.kyb-fap {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);

  > .container {
    background-color: var(--emu-semantic-colors-secondary-light-grey-900);
    padding-top: 12px;
    padding-right: var(--emu-semantic-spacing-horizontal);
    padding-bottom: 12px;
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  .image,
  &__close.cmp-button,
  &__req-text,
  &__url-text {
    display: none;
  }

  &__form-wrapper.cmp-container {
    form.cmp-form {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .cmp-form-text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          flex: 1;
          color: var(--emu-semantic-colors-primary-red-200);
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1.33px;
        }

        input {
          width: 50%;
          min-width: 90px;
          padding-top: 9px;
          padding-right: 12px;
          padding-bottom: 9px;
          padding-left: 12px;
          margin-right: var(--emu-common-spacing-small);
          border: var(--emu-semantic-border-width-thin) solid
            var(--emu-semantic-colors-secondary-light-grey-1000);
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          color: var(--emu-semantic-colors-secondary-dark-grey-800);

          &.error {
            border-color: var(--emu-semantic-colors-secondary-red-100);
          }

          &::placeholder {
            color: var(--emu-semantic-colors-secondary-light-grey-500);
          }
        }
      }

      > .button {
        width: 50%;
        max-width: 110px;

        button {
          display: block;
          width: 100%;
          text-align: center;
          color: var(--emu-semantic-colors-secondary-light-grey-600);
          background-color: var(--emu-semantic-colors-primary-red-100);
          font-size: 11px;
          letter-spacing: 2px;
          line-height: 16px;
          padding-top: 12px;
          padding-bottom: 12px;
          text-transform: uppercase;
        }
      }

      .text {
        flex: 1;
      }
    }
  }

  @include mq('medium') {
    position: fixed;
    top: 172px;
    right: 0;
    z-index: var(--emu-common-other-z-index-layer);
    height: 218px;
    display: flex;
    padding: var(--emu-semantic-spacing-none);
    transform: translateX(100%) translateX(-48px);
    transition: all 0.3s linear;

    &.kyb-fap--shown {
      transform: translateX(0);
    }

    > .container {
      padding: var(--emu-semantic-spacing-none);
      background-color: var(--emu-semantic-colors-secondary-light-grey-100);
    }

    .image,
    &__close.cmp-button,
    &__req-text {
      display: block;
    }

    // increasing specificity
    .kyb-fap__req-text {
      letter-spacing: normal;
    }

    label {
      display: none;
    }

    .image .cmp-image {
      height: 100%;
      cursor: pointer;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__form-wrapper.cmp-container {
      padding-right: 40px;
      padding-top: 20px;
      padding-left: 40px;

      > .button {
        margin-bottom: var(--emu-semantic-spacing-vertical);
        text-align: right;

        .kyb-fap__close {
          background-color: var(--emu-semantic-colors-none);
          display: inline-block;
          margin-left: auto;
          padding: 0;
          border-color: var(--emu-semantic-colors-none);
          margin-right: 15px;

          &:focus,
          &:active,
          &:hover {
            outline: none;
          }

          span {
            position: relative;
            display: block;
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;

            &::after,
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 10px;
              width: 2px;
              height: 100%;
              transform-origin: center;
              background-color: var(
                --emu-semantic-colors-secondary-dark-grey-500
              );
            }

            &::before {
              transform: rotate(45deg);
            }

            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }

      form.cmp-form {
        display: block;
        max-width: 190px;

        .cmp-form-text {
          display: block;
          input {
            width: 100%;
            border-color: var(--emu-semantic-colors-primary-red-100);
            text-align: center;
            height: 45px;
          }
        }

        .text,
        .button {
          max-width: 146px;
          width: 100%;
          margin-bottom: var(--emu-semantic-spacing-vertical);
        }

        .button {
          button {
            padding-top: var(--emu-common-spacing-small);
            padding-bottom: var(--emu-common-spacing-small);
            text-transform: initial;
            font-size: 14px;
            letter-spacing: normal;
            border: var(--emu-semantic-border-width-thin) solid
              var(--emu-semantic-colors-secondary-light-grey-1000);
          }
        }
      }
    }
  }

  @include mq('992px') {
    top: 135px;

    &__form-wrapper.cmp-container {
      padding-left: var(--emu-semantic-spacing-horizontal);
      padding-top: 10px;

      form.cmp-form {
        max-width: 175px;

        .text,
        .button {
          max-width: 165px;
        }
      }
    }
  }
}
