body {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

p,
li {
  margin: var(--emu-common-spacing-none);
}
