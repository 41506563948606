#faq {
  .faq {
    &__title {
      margin-top: 30px;
      margin-bottom: 10px;
      .cmp-title__text {
        font-size: 27px;
        letter-spacing: 4.05px;
        line-height: 30px;
        @include mq('medium') {
          font-size: 24px;
          letter-spacing: 3.6;
        }
      }
    }

    &__desc {
      margin-bottom: 20px;

      @include mq('medium') {
        margin-bottom: 25px;
      }
    }
  }
}
