h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  letter-spacing: 3px;
}

.aaaem-title {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
    letter-spacing: 3px;
  }

  h1 {
    letter-spacing: 3.6px;
  }

  &__color {
    &-primary {
      color: var(--emu-semantic-colors-primary-red-100);
    }

    &-secondary {
      color: var(--emu-semantic-colors-secondary-blue-100);
    }

    &-white {
      color: var(--emu-common-colors-white);
    }

    &-black {
      color: var(--emu-common-colors-black);
    }
  }
}
