@import "//use.typekit.net/sic6ayn.css";
body {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  font-family: var(--emu-semantic-font-families-body);
  line-height: var(--emu-semantic-line-heights-narrow-large);
}

p, li {
  margin: var(--emu-common-spacing-none);
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-semantic-colors-secondary-light-grey-200);
  --emu-semantic-colors-surface-dark: var(--emu-semantic-colors-secondary-light-grey-100);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-red-100: #ae504b;
  --emu-semantic-colors-primary-red-200: #dd6f66;
  --emu-semantic-colors-primary-red-300: #773d3a;
  --emu-semantic-colors-primary-red-400: #f0dad8;
  --emu-semantic-colors-secondary-red-100: red;
  --emu-semantic-colors-secondary-blue-100: #137193;
  --emu-semantic-colors-secondary-blue-200: #2a6496;
  --emu-semantic-colors-secondary-dark-grey-100: #3e3e3e;
  --emu-semantic-colors-secondary-dark-grey-200: #4d4d4d;
  --emu-semantic-colors-secondary-dark-grey-300: #4e5355;
  --emu-semantic-colors-secondary-dark-grey-400: #4e5457;
  --emu-semantic-colors-secondary-dark-grey-500: #666;
  --emu-semantic-colors-secondary-dark-grey-600: #d1d1d1;
  --emu-semantic-colors-secondary-dark-grey-700: #333;
  --emu-semantic-colors-secondary-dark-grey-800: #555;
  --emu-semantic-colors-secondary-light-grey-100: #f5f5f5;
  --emu-semantic-colors-secondary-light-grey-200: #fafafa;
  --emu-semantic-colors-secondary-light-grey-300: #b1b1b1;
  --emu-semantic-colors-secondary-light-grey-400: #ebeced;
  --emu-semantic-colors-secondary-light-grey-500: #a8a8a8;
  --emu-semantic-colors-secondary-light-grey-600: #fff;
  --emu-semantic-colors-secondary-light-grey-700: #ffffff29;
  --emu-semantic-colors-secondary-light-grey-800: #c6c6c6;
  --emu-semantic-colors-secondary-light-grey-900: #ebebeb;
  --emu-semantic-colors-secondary-light-grey-1000: #ccc;
  --emu-semantic-colors-secondary-light-grey-1100: #f6f6f6;
  --emu-semantic-font-weight-bold: 700;
  --emu-semantic-font-weight-regular: 300;
  --emu-semantic-font-families-body: "nimbus-sans-extended", sans-serif;
  --emu-semantic-font-families-heading: "nimbus-sans-extended", sans-serif;
  --emu-semantic-font-families-mono: "roboto-condensed", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: 15px;
  --emu-semantic-spacing-vertical: 15px;
  --emu-semantic-font-sizes-narrow-medium: 9px;
  --emu-semantic-font-sizes-narrow-large: 12px;
  --emu-semantic-font-sizes-narrow-xl: 14px;
  --emu-semantic-font-sizes-narrow-xxl: 20px;
  --emu-semantic-font-sizes-narrow-xxxl: 24px;
  --emu-semantic-font-sizes-wide-medium: 9px;
  --emu-semantic-font-sizes-wide-large: 12px;
  --emu-semantic-font-sizes-wide-xl: 14px;
  --emu-semantic-font-sizes-wide-xxl: 20px;
  --emu-semantic-font-sizes-wide-xxxl: 24px;
  --emu-semantic-line-heights-narrow-medium: 13px;
  --emu-semantic-line-heights-narrow-large: 17px;
  --emu-semantic-line-heights-narrow-xl: 20px;
  --emu-semantic-line-heights-narrow-xxl: 28px;
  --emu-semantic-line-heights-narrow-xxxl: 30px;
  --emu-semantic-line-heights-wide-medium: 13px;
  --emu-semantic-line-heights-wide-large: 17px;
  --emu-semantic-line-heights-wide-xl: 22px;
  --emu-semantic-line-heights-wide-xxl: 28px;
  --emu-semantic-line-heights-wide-xxxl: 30px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 990px;
  --emu-semantic-sizing-breakpoints-x-large: 992px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / 25px var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / 25px var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: 25px;
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: 15px;
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: 70px;
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: 15px;
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-cards-teaser-title-max-width: 100%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: 10px;
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-secondary-light-grey-200);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-right-narrow: 15px;
  --emu-component-lists-accordion-margin-right-wide: 28px;
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-left-narrow: 15px;
  --emu-component-lists-accordion-margin-left-wide: 20px;
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-secondary-dark-grey-600);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-body-padding-right-narrow: 45px;
  --emu-component-lists-accordion-item-body-padding-right-wide: 75px;
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-body-padding-left-narrow: 50px;
  --emu-component-lists-accordion-item-body-padding-left-wide: 45px;
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-header-padding-right-narrow: 12px;
  --emu-component-lists-accordion-item-header-padding-right-wide: 5px;
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-header-padding-left-narrow: 12px;
  --emu-component-lists-accordion-item-header-padding-left-wide: 5px;
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: 12px;
  --emu-component-lists-accordion-item-header-icon-size-width: 12px;
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-default-light: #e6e6e6;
  --emu-component-lists-navigation-banner-item-color-border-default-dark: #e6e6e6;
  --emu-component-lists-navigation-banner-item-color-border-hover-light: #e6e6e6;
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: #e6e6e6;
  --emu-component-lists-navigation-banner-item-text-color-default-light: #5a5a5a;
  --emu-component-lists-navigation-banner-item-text-color-default-dark: #5a5a5a;
  --emu-component-lists-navigation-banner-item-text-color-hover-light: #5a5a5a;
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: #5a5a5a;
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-banner-padding-top-wide: 10px;
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-semantic-colors-secondary-light-grey-100);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-right-wide: 27px;
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-left-wide: 5px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-secondary-light-grey-800);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-padding-top-narrow: 12px;
  --emu-component-actions-button-padding-top-wide: 12px;
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-bottom-narrow: 12px;
  --emu-component-actions-button-padding-bottom-wide: 12px;
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: 0;
  --emu-component-actions-button-outline-width-primary-outline: 0;
  --emu-component-actions-button-outline-width-secondary-filled: 0;
  --emu-component-actions-button-outline-width-secondary-outline: 0;
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-secondary-light-grey-600);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-primary-red-200);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-secondary-dark-grey-400);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-secondary-light-grey-700);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-secondary-light-grey-700);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-primary-red-300);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: 10px;
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-secondary-light-grey-300);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-secondary-light-grey-200);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-none);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-none);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: #ffffffe6;
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: 18px;
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: 18px;
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-secondary-blue-100);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-secondary-blue-100);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-secondary-blue-100);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: 12px;
  --emu-component-containers-carousel-indicators-dots-size-wide: 10px;
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: 20px;
  --emu-component-containers-gallery-padding-top-wide: 25px;
  --emu-component-containers-gallery-padding-right-narrow: 20px;
  --emu-component-containers-gallery-padding-right-wide: 25px;
  --emu-component-containers-gallery-padding-bottom-narrow: 40px;
  --emu-component-containers-gallery-padding-bottom-wide: 30px;
  --emu-component-containers-gallery-padding-left-narrow: 20px;
  --emu-component-containers-gallery-padding-left-wide: 25px;
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-margin-bottom-narrow: 20px;
  --emu-component-containers-gallery-margin-bottom-wide: 30px;
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-color-border-light: var(--emu-semantic-colors-secondary-light-grey-800);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: 33.3%;
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-none);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-semantic-colors-text-light);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: 25px;
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: 20px;
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-spacing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: 20px;
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-spacing-none);
  --emu-component-layers-cookie-consent-banner-max-width: 1066px;
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  text-align: left;
  margin: 0;
  padding: 0;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: none;
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

span, a, b, i {
  font: inherit;
  color: inherit;
}

.desktop-view {
  display: none;
}

@media (min-width: 990px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 990px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.text--font-body {
  font-family: var(--emu-semantic-font-families-body);
}

.text--font-heading {
  font-family: var(--emu-semantic-font-families-heading);
}

.text--font-mono {
  font-family: var(--emu-semantic-font-families-mono);
}

.text--color-white {
  color: var(--emu-semantic-colors-text-dark);
}

.text--color-light-grey {
  color: var(--emu-semantic-colors-secondary-light-grey-200);
}

.text--color-dark-grey {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

.text--color-regular {
  color: var(--emu-semantic-colors-text-light);
}

.text--color-red {
  color: var(--emu-semantic-colors-primary-red-100);
}

.text--color-blue {
  color: var(--emu-semantic-colors-secondary-blue-100);
}

.root > .aaaem-container {
  padding: 0;
}

.kyb-full-width-container, .kyb-full-width-container.aaaem-container {
  width: 100%;
  max-width: none;
  padding: var(--emu-common-spacing-none);
}

.kyb-custom-container {
  padding: var(--emu-common-spacing-none);
  max-width: none;
}

.kyb-custom-container > .aem-Grid {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
  max-width: none;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .kyb-custom-container > .aem-Grid {
    max-width: var(--emu-semantic-sizing-breakpoints-medium);
  }
}

@media (min-width: 992px) {
  .kyb-custom-container > .aem-Grid {
    max-width: var(--emu-semantic-sizing-breakpoints-large);
  }
}

.bg--secondary-light-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-100);
}

.bg--secondary-regular-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-200);
}

.bg--secondary-medium-grey {
  background-color: var(--emu-semantic-colors-secondary-light-grey-400);
}

.bg--secondary-dark-grey {
  background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

.bg--primary-light-red {
  background-color: var(--emu-semantic-colors-primary-red-200);
}

.bg--primary-dark-red {
  background-color: var(--emu-semantic-colors-primary-red-100);
}

.bg--secondary-light-blue {
  background-color: var(--emu-semantic-colors-secondary-blue-100);
}

.bg--secondary-dark-blue {
  background-color: var(--emu-semantic-colors-secondary-blue-200);
}

.tablet-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-view {
    display: block;
  }
}

@media (min-width: 990px) {
  .tablet-view {
    display: none;
  }
}

.title--large .cmp-title__text {
  letter-spacing: 3.6px;
  font-size: 24px;
  line-height: 30px;
}

.text--large {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  letter-spacing: 1.4px;
}

.text--small {
  font-size: 10px;
  line-height: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: .75px;
}

.image-centric-container.aaaem-container > .aem-Grid .image .aaaem-image {
  width: 140px;
}

.image-centric-container.aaaem-container > .aem-Grid .image .aaaem-image img {
  display: block;
}

@media (min-width: 768px) {
  .image-centric-container.aaaem-container > .aem-Grid .image .aaaem-image {
    width: 160px;
  }
}

.common__visual-results {
  padding: 10px 30px 30px;
}

.common__visual-results .fifth-sec__left-section .text--large {
  font-size: 32px;
  line-height: 45px;
}

.common__visual-results .fifth-sec__left-section .text--large p {
  font-weight: var(--emu-semantic-font-weight-bold);
}

.common__visual-results .fifth-sec__left-section .text--medium {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  padding-bottom: var(--emu-semantic-spacing-vertical);
  border-bottom: 1px solid var(--emu-semantic-colors-primary-red-200);
  margin-bottom: 20px;
  line-height: 34px;
}

.common__visual-results .fifth-sec__right-section .aaaem-text {
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

@media (min-width: 768px) {
  .common__visual-results {
    padding-right: 10px;
  }

  .common__visual-results > .aem-Grid {
    align-items: center;
    margin-top: 20px;
    display: flex;
  }

  .common__visual-results > .aem-Grid > .container:first-child {
    width: 38%;
    margin-right: 35px;
  }

  .common__visual-results > .aem-Grid > .container:last-child {
    flex: 1;
  }

  .common__visual-results .fifth-sec__left-section {
    border-right: 1px solid var(--emu-semantic-colors-primary-red-200);
  }

  .common__visual-results .fifth-sec__left-section .text--large {
    font-size: 18px;
    line-height: 25px;
  }

  .common__visual-results .fifth-sec__left-section .text--medium {
    border-bottom: none;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 22px;
  }
}

@media (min-width: 992px) {
  .common__visual-results .fifth-sec__left-section .text--large {
    font-size: 32px;
    line-height: 45px;
  }

  .common__visual-results .fifth-sec__left-section .text--medium {
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    padding-bottom: var(--emu-common-spacing-none);
    line-height: 34px;
  }
}

.common-treatment-chart__table {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

.common-treatment-chart__table-header {
  padding-top: var(--emu-semantic-spacing-vertical);
  padding-right: 10px;
  padding-bottom: var(--emu-semantic-spacing-vertical);
  padding-left: 10px;
}

.common-treatment-chart__table-header.aaaem-text p {
  font-weight: var(--emu-common-font-weight-bold);
  font-size: 15px;
  font-weight: var(--emu-common-font-weight-bold);
}

.common-treatment-chart__table-elem {
  max-width: 100%;
  overflow-x: auto;
}

.common-treatment-chart__table-elem table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--emu-semantic-colors-secondary-light-grey-600);
  border-top: none;
}

.common-treatment-chart__table-elem table tr, .common-treatment-chart__table-elem table td, .common-treatment-chart__table-elem table th {
  border: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-grey-600);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  text-align: center;
  font-weight: normal;
}

.common-treatment-chart__table-elem table sup {
  font-size: 6px;
}

.common-treatment-chart__table-elem--endpoint {
  margin-bottom: 30px;
}

.common-treatment-chart__table-elem--endpoint tr:first-child th, .common-treatment-chart__table-elem--endpoint tr:first-child td, .common-treatment-chart__table-elem--endpoint tr:nth-child(2) th, .common-treatment-chart__table-elem--endpoint tr:nth-child(2) td {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  letter-spacing: .6px;
  font-weight: bold;
}

.common-treatment-chart__table-elem--endpoint tr:last-child td:not(:first-child), .common-treatment-chart__table-elem--endpoint tr:nth-child(3) td:not(:first-child) {
  color: var(--emu-semantic-colors-primary-red-100);
}

.common-treatment-chart__table-elem--endpoint tr td:first-child {
  text-align: left;
}

.common-treatment-chart__table-elem--treatment-details {
  margin-top: 30px;
}

.common-treatment-chart__table-elem--treatment-details table tr:first-child th, .common-treatment-chart__table-elem--treatment-details table tr:first-child td {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  letter-spacing: .6px;
  font-weight: bold;
}

.common-treatment-chart__table-elem--treatment-details table tr:nth-child(2) td:not(:nth-child(1)):not(:nth-child(2)), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(4) td:not(:nth-child(1)):not(:nth-child(2)) {
  color: var(--emu-semantic-colors-primary-red-100);
}

.common-treatment-chart__table-elem--treatment-details table tr:nth-child(2) td:nth-child(1), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(2) td:nth-child(2), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(4) td:nth-child(1), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(4) td:nth-child(2) {
  text-align: left;
}

.common-treatment-chart__table-elem--treatment-details table tr:nth-child(2) td:nth-child(2), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(4) td:nth-child(2) {
  font-size: 9px;
  font-weight: bold;
}

.common-treatment-chart__table-elem--treatment-details table tr:nth-child(3) td:not(:nth-child(1)), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(5) td:not(:nth-child(1)) {
  color: var(--emu-semantic-colors-primary-red-100);
}

.common-treatment-chart__table-elem--treatment-details table tr:nth-child(3) td:nth-child(1), .common-treatment-chart__table-elem--treatment-details table tr:nth-child(5) td:nth-child(1) {
  text-align: left;
  font-size: 9px;
  font-weight: bold;
}

.common-treatment-chart__table-disclaimers {
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  line-height: 14px;
}

.common-treatment-chart__table-disclaimers.cmp-text p {
  letter-spacing: .75px;
  line-height: 14px;
}

@media (min-width: 768px) {
  .common-treatment-chart__table-disclaimers.cmp-text p {
    letter-spacing: normal;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  .common-treatment-chart__table-disclaimers.cmp-text p {
    letter-spacing: 0;
    font-size: 10px;
    line-height: 14px;
  }
}

.common-treatment-chart__table-disclaimers.cmp-text p, .common-treatment-chart__table-disclaimers.cmp-text sup {
  font-family: var(--emu-semantic-font-families-mono);
}

@media (min-width: 768px) {
  .common-treatment-chart__table-disclaimers.cmp-text p, .common-treatment-chart__table-disclaimers.cmp-text sup {
    font-family: var(--emu-semantic-font-families-heading);
  }
}

@media (min-width: 992px) {
  .common-treatment-chart__table-disclaimers.cmp-text p, .common-treatment-chart__table-disclaimers.cmp-text sup {
    font-family: var(--emu-semantic-font-families-mono);
  }
}

.common-treatment-chart__table-disclaimers.cmp-text sup {
  font-size: 5px;
}

@media (min-width: 768px) {
  .common-treatment-chart__table-disclaimers.cmp-text sup {
    font-size: 7px;
  }
}

@media (min-width: 992px) {
  .common-treatment-chart__table-disclaimers.cmp-text sup {
    font-size: 5px;
  }
}

.kyb-card-container .card > div, .kyb-card-container .card .aaaem-card {
  margin-bottom: 3px;
}

.kyb-card-container .card > div__body .title .cmp-title__text, .kyb-card-container .card .aaaem-card__body .title .cmp-title__text {
  max-width: 65%;
  font-size: 15px;
  line-height: 21px;
}

.kyb-card-container .card > div .button, .kyb-card-container .card .aaaem-card .button {
  padding-top: 29px;
  padding-bottom: 9px;
}

@media (min-width: 768px) {
  .kyb-card-container .card > div, .kyb-card-container .card .aaaem-card {
    height: 100%;
    margin-bottom: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    position: relative;
  }

  .kyb-card-container .card > div__body .title .cmp-title__text, .kyb-card-container .card .aaaem-card__body .title .cmp-title__text {
    letter-spacing: .75px;
    max-width: 85%;
  }

  .kyb-card-container .card > div footer, .kyb-card-container .card .aaaem-card footer {
    padding-bottom: 68px;
  }

  .kyb-card-container .card > div footer .aaaem-container, .kyb-card-container .card .aaaem-card footer .aaaem-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .kyb-card-container .card > div footer .cmp-text p, .kyb-card-container .card .aaaem-card footer .cmp-text p {
    letter-spacing: .26px;
    font-size: 13px;
  }

  .kyb-card-container .card > div .button, .kyb-card-container .card .aaaem-card .button {
    padding: var(--emu-common-spacing-none);
    position: static;
  }

  .kyb-card-container .card > div .aaaem-button, .kyb-card-container .card .aaaem-card .aaaem-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

@media (min-width: 992px) {
  .kyb-card-container .card > div__body .title .cmp-title__text, .kyb-card-container .card .aaaem-card__body .title .cmp-title__text {
    max-width: 65%;
  }

  .kyb-card-container .card > div footer .cmp-text p, .kyb-card-container .card .aaaem-card footer .cmp-text p {
    letter-spacing: 1px;
    font-size: 12px;
  }
}

.g-recaptcha {
  z-index: var(--emu-common-other-z-index-modal);
  display: block;
  position: fixed;
  top: 40px;
}

body.r-banner-open .header__eyebrow, body.r-banner-open header.header {
  z-index: var(--emu-common-other-z-index-header);
  top: 47px;
}

body.r-banner-open header.header {
  background-color: var(--emu-common-colors-transparent);
}

#home .double-chin-teaser .content-container .disclaimer {
  position: absolute;
  top: -50px;
}

@media (min-width: 768px) {
  #home .double-chin-teaser .content-container .disclaimer {
    position: relative;
    top: 12px;
  }
}

@media (min-width: 992px) {
  #home .double-chin-teaser .content-container .disclaimer {
    top: 0;
  }
}

@media (min-width: 768px) {
  #home .double-chin-teaser .content-container {
    padding-top: 52px;
    padding-right: var(--emu-common-spacing-none);
    width: 60%;
    padding-bottom: 16px;
    padding-left: 36px;
  }

  #home .double-chin-teaser .content-container .cmp-teaser__description {
    color: var(--emu-semantic-colors-secondary-light-grey-400);
    max-width: 94%;
  }
}

@media (min-width: 992px) {
  #home .double-chin-teaser .content-container {
    width: 55%;
    padding: 52px 44px 36px;
  }

  #home .double-chin-teaser .content-container .cmp-teaser__description {
    padding-right: var(--emu-common-spacing-none);
  }
}

#home .real-stories-teaser .content-container {
  margin-top: 65px;
}

#home .real-stories-teaser .content-container .content {
  flex-wrap: nowrap;
}

#home .real-stories-teaser .content-container .disclaimer {
  position: absolute;
  top: -70px;
}

@media (min-width: 475px) {
  #home .real-stories-teaser .content-container .disclaimer {
    top: -110px;
  }
}

@media (min-width: 768px) {
  #home .real-stories-teaser .content-container .disclaimer {
    position: relative;
    top: 35px;
  }
}

@media (min-width: 992px) {
  #home .real-stories-teaser .content-container .disclaimer {
    top: 65px;
  }
}

@media (min-width: 768px) {
  #home .real-stories-teaser .content-container {
    margin-top: var(--emu-common-spacing-none);
    padding-left: 36px;
  }

  #home .real-stories-teaser .content-container .content {
    flex-wrap: nowrap;
  }

  #home .real-stories-teaser .content-container .content .cmp-teaser__description {
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #home .real-stories-teaser .content-container {
    padding-left: 44px;
    padding-top: var(--emu-semantic-spacing-horizontal);
  }

  #home .real-stories-teaser .content-container .content .aaaem-teaser__title {
    margin-bottom: var(--emu-component-cards-teaser-title-margin-bottom-wide);
  }

  #home .real-stories-teaser .content-container .content .aaaem-teaser__action-link:hover {
    background: var(--emu-semantic-colors-primary-red-300);
  }

  #home .real-stories-teaser .content-container .content .cmp-teaser__description {
    margin-bottom: 10px;
  }

  #home .real-stories-teaser .content-container .content .cmp-teaser__description p {
    margin-top: var(--emu-component-cards-teaser-description-margin-top-narrow);
  }
}

#home .alle-rewards-teaser .content-container {
  padding: var(--emu-common-spacing-none);
}

#home .alle-rewards-teaser .content-container .content {
  padding: var(--emu-semantic-spacing-horizontal);
  text-align: left;
}

#home .alle-rewards-teaser .content-container .content .cmp-teaser__description, #home .alle-rewards-teaser .content-container .content .aaaem-teaser__action-container {
  margin-left: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .alle-rewards-teaser .content-container .content {
    padding: var(--emu-common-spacing-none);
    padding-bottom: 80px;
  }

  #home .alle-rewards-teaser .content-container .content .aaaem-teaser__action-container {
    margin-left: auto;
  }

  #home .alle-rewards-teaser .content-container .content .cmp-teaser__description {
    display: none;
  }

  #home .alle-rewards-teaser .content-container .content .aaaem-teaser__action-link {
    border-color: var(--emu-semantic-colors-secondary-light-grey-600);
  }

  #home .alle-rewards-teaser .content-container .content .aaaem-teaser__action-link:hover {
    background: var(--emu-semantic-colors-secondary-light-grey-700);
  }
}

#home .kyb-card-container .card > div, #home .kyb-card-container .card .aaaem-card {
  margin-bottom: 3px;
}

#home .kyb-card-container .card > div__body .title .cmp-title__text, #home .kyb-card-container .card .aaaem-card__body .title .cmp-title__text {
  max-width: 65%;
  font-size: 16px;
  line-height: 22.4px;
}

#home .kyb-card-container .card > div .button, #home .kyb-card-container .card .aaaem-card .button {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  #home .kyb-card-container .card > div, #home .kyb-card-container .card .aaaem-card {
    margin-bottom: var(--emu-common-spacing-none);
  }

  #home .kyb-card-container .card > div__body .title .cmp-title__text, #home .kyb-card-container .card .aaaem-card__body .title .cmp-title__text {
    letter-spacing: 2.5px;
    max-width: 100%;
  }

  #home .kyb-card-container .card > div footer, #home .kyb-card-container .card .aaaem-card footer {
    padding-bottom: var(--emu-semantic-spacing-horizontal);
  }

  #home .kyb-card-container .card > div footer .aaaem-container, #home .kyb-card-container .card .aaaem-card footer .aaaem-container {
    flex-direction: column;
    align-items: flex-start;
  }

  #home .kyb-card-container .card > div .button, #home .kyb-card-container .card .aaaem-card .button {
    padding: var(--emu-common-spacing-none);
    position: relative;
  }

  #home .kyb-card-container .card > div .aaaem-button, #home .kyb-card-container .card .aaaem-card .aaaem-button {
    position: relative;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  #home .kyb-card-container .card > div footer .aaaem-container, #home .kyb-card-container .card .aaaem-card footer .aaaem-container {
    flex-direction: row;
  }
}

#home .cmp-container-header {
  border: none;
}

#about-smf .heading__about-smf {
  max-width: 86%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #about-smf .heading__about-smf {
    max-width: calc(86% - 70px);
  }
}

#about-smf .heading__about-smf .title {
  margin-top: 20px;
  margin-bottom: 10px;
}

#about-smf .heading__about-smf .text {
  margin-bottom: 20px;
}

#about-smf .card__what-causes-smf {
  padding-bottom: var(--emu-semantic-spacing-horizontal);
}

@media (min-width: 768px) {
  #about-smf .card__what-causes-smf {
    padding-bottom: 5px;
  }
}

#about-smf .card__what-causes-smf .aaaem-title, #about-smf .card__what-causes-smf .aaaem-text, #about-smf .card__what-causes-smf .aaaem-container {
  max-width: 81%;
  float: none;
  clear: both;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 992px) {
  #about-smf .card__what-causes-smf .aaaem-title, #about-smf .card__what-causes-smf .aaaem-text, #about-smf .card__what-causes-smf .aaaem-container {
    max-width: none;
  }
}

#about-smf .card__what-causes-smf .text-container__what-causes-smf.aaaem-text {
  max-width: none;
  padding: 0 var(--emu-semantic-spacing-horizontal);
}

@media (min-width: 768px) {
  #about-smf .card__what-causes-smf .text-container__what-causes-smf.aaaem-text {
    max-width: 83.33%;
  }
}

@media (min-width: 992px) {
  #about-smf .card__what-causes-smf .text-container__what-causes-smf.aaaem-text {
    max-width: 66.67%;
  }
}

#about-smf .card__what-causes-smf .text .aaaem-text {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #about-smf .card__what-causes-smf .text .aaaem-text {
    margin-bottom: 35px;
  }
}

#about-smf .card__what-causes-smf .title {
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  #about-smf .card__what-causes-smf .title {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  #about-smf .card__what-causes-smf .image-container__what-causes-smf.aaaem-container {
    width: 650px;
  }

  #about-smf .card__what-causes-smf .image-container__what-causes-smf.aaaem-container > .aem-Grid {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  #about-smf .card__what-causes-smf .image-container__what-causes-smf.aaaem-container {
    width: 100%;
    max-width: var(--emu-semantic-sizing-breakpoints-large);
  }

  #about-smf .card__what-causes-smf .image-container__what-causes-smf.aaaem-container > .aem-Grid {
    justify-content: center;
  }
}

#about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid > .container {
  max-width: 260px;
  width: 100%;
}

@media (min-width: 992px) {
  #about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid > .container {
    width: 250px;
  }

  #about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid > .container img {
    max-width: 218px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

#about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid .aaaem-text {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid .aaaem-text {
    margin-bottom: 20px;
  }
}

#about-smf .card__what-causes-smf .image-container__what-causes-smf > .aem-Grid .aaaem-text p {
  font-size: 16px;
}

#about-smf .third-sec__bothered-by-smf {
  padding: 0 25px 25px;
}

#about-smf .third-sec__bothered-by-smf > .aem-Grid > .title .cmp-title__text {
  width: 60%;
  margin: 20px auto 10px;
  line-height: 28px;
}

#about-smf .third-sec__bothered-by-smf > .aem-Grid > .text {
  margin-top: 10px;
  margin-bottom: 35px;
}

#about-smf .third-sec__bothered-by-smf > .aem-Grid > .text .aaaem-text {
  width: 70%;
  margin: auto;
}

#about-smf .third-sec__bothered-by-smf > .aem-Grid .aaaem-text:not(.text--color-blue) {
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  letter-spacing: 0;
}

#about-smf .third-sec__bothered-by-smf .text--jumbo {
  font-size: 70px;
  line-height: 1;
}

#about-smf .third-sec__bothered-by-smf .third-section__text-container .third-section__text-top {
  border-bottom: 1px solid var(--emu-semantic-colors-primary-red-200);
  grid-gap: 5%;
  align-items: center;
  gap: 5%;
  margin-bottom: 35px;
  padding-bottom: 10px;
  display: flex;
}

#about-smf .third-sec__bothered-by-smf .third-section__text-container .third-section__text-bottom {
  line-height: 22px;
}

@media (min-width: 768px) {
  #about-smf .third-sec__bothered-by-smf > .aem-Grid > .title .cmp-title__text {
    width: 100%;
    margin-bottom: var(--emu-common-spacing-none);
  }

  #about-smf .third-sec__bothered-by-smf > .aem-Grid > .text .aaaem-text {
    width: 44%;
  }

  #about-smf .third-sec__bothered-by-smf .text--jumbo {
    font-size: 75px;
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container {
    clear: both;
    overflow: hidden;
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container > .container {
    width: 54%;
    float: left;
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container > .container:last-child {
    width: calc(46% - 20px);
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container .third-section__text-top {
    margin-bottom: var(--emu-common-spacing-none);
    border-bottom: none;
    border-right: 1px solid var(--emu-semantic-colors-primary-red-200);
    grid-gap: 10px;
    gap: 10px;
    margin-right: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  #about-smf .third-sec__bothered-by-smf {
    padding-left: 35px;
    padding-right: 35px;
  }

  #about-smf .third-sec__bothered-by-smf > .aem-Grid > .text .aaaem-text {
    width: 70%;
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container > .container {
    width: 54%;
    float: left;
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container > .container:last-child {
    width: calc(46% - 30px);
  }

  #about-smf .third-sec__bothered-by-smf .third-section__text-container .third-section__text-top {
    grid-gap: 30px;
    gap: 30px;
    margin-right: 30px;
    padding-right: 30px;
  }
}

#about-smf .fourth-sec__kybella-may-help-smf {
  border-bottom: 1px solid var(--emu-semantic-colors-secondary-light-grey-800);
  padding-left: 30px;
  padding-right: 30px;
}

#about-smf .fourth-sec__kybella-may-help-smf a {
  text-decoration: none;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid {
  padding-bottom: var(--emu-semantic-spacing-vertical);
  flex-direction: column-reverse;
  display: flex;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container {
  width: 100%;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container img {
  width: auto;
  height: auto;
  max-width: 100%;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container .title {
  margin-top: 35px;
  margin-bottom: 10px;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container > .text {
  margin-bottom: 20px;
  padding-left: 10px;
}

#about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container > .text .aaaem-text:not(.title) {
  color: var(--emu-semantic-colors-text-light);
}

@media (min-width: 768px) {
  #about-smf .fourth-sec__kybella-may-help-smf {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    border-top: 2px solid var(--emu-semantic-colors-secondary-light-grey-600);
  }

  #about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid {
    padding-bottom: var(--emu-common-spacing-none);
    flex-direction: row;
  }

  #about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container {
    width: 50%;
    padding-left: 30px;
  }

  #about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container .title {
    padding-left: var(--emu-common-spacing-none);
    margin-top: 20px;
  }

  #about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container .aaaem-container > .text {
    padding-left: var(--emu-common-spacing-none);
    margin-bottom: 10px;
  }
}

@media (min-width: 992px) {
  #about-smf .fourth-sec__kybella-may-help-smf {
    padding: 50px 20px 35px;
  }

  #about-smf .fourth-sec__kybella-may-help-smf > .aem-Grid > .container:first-child {
    padding-left: var(--emu-common-spacing-none);
  }
}

#about .tabs--about-kyb {
  margin: var(--emu-common-spacing-none);
  position: relative;
}

#about .tabs--about-kyb .aaaem-container {
  padding: var(--emu-common-spacing-none);
}

#about .tabs--about-kyb .aaaem-tabs__tablist {
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 11px;
}

#about .tabs--about-kyb .aaaem-tabs__tablist .aaaem-tabs__tab {
  margin-top: var(--emu-common-spacing-none);
  margin-right: 1px;
  margin-bottom: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
}

#about .tabs--about-kyb .aaaem-tabs__tablist .aaaem-tabs__tab a {
  white-space: nowrap;
  height: 30px;
  background: var(--emu-semantic-colors-secondary-dark-grey-400);
  border-bottom: var(--emu-common-spacing-none);
  letter-spacing: 0;
  font-size: 7px;
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-common-colors-white);
  width: 95px;
  justify-content: center;
  align-items: center;
  display: flex;
}

#about .tabs--about-kyb .aaaem-tabs__tablist .aaaem-tabs__tab a[aria-selected="true"] {
  background-color: var(--emu-semantic-colors-primary-red-100);
}

#about .heading--about-kyb {
  padding-bottom: 10px;
}

#about .heading--about-kyb .title .aaaem-title {
  margin-top: 20px;
}

#about .heading--about-kyb .title .aaaem-title h2 {
  letter-spacing: 4px;
  font-size: 27px;
  line-height: 30px;
}

#about .heading--about-kyb .text {
  margin-top: 10px;
  margin-bottom: 10px;
}

#about .heading--about-kyb .text .aaaem-text {
  width: 80%;
  margin: auto;
}

#about .heading--about-kyb .text .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
}

#about .about--kybella-box {
  padding: 50px 5px 25px 35px;
}

@media (min-width: 768px) {
  #about .about--kybella-box-image-desktop {
    display: none;
  }
}

@media (min-width: 992px) {
  #about .about--kybella-box-image-desktop {
    display: block;
  }
}

#about .about--kybella-box-image-tablet {
  display: none;
}

@media (min-width: 768px) {
  #about .about--kybella-box-image-tablet {
    height: 339px;
    padding-top: 25px;
    display: block;
  }
}

@media (min-width: 992px) {
  #about .about--kybella-box-image-tablet {
    display: none;
  }
}

#about .about--kybella-box-image-tablet img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left;
  object-position: left;
}

#about .about--kybella-box-top > .aem-Grid, #about .about--kybella-box-bottom > .aem-Grid {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

#about .about--kybella-box-top {
  width: 100%;
}

#about .about--kybella-box-top .cmp-title__text {
  margin: var(--emu-common-spacing-none);
}

#about .about--kybella-box-top ul {
  padding-left: 20px;
}

#about .about--kybella-box-top ul li {
  color: inherit;
  letter-spacing: .9px;
  padding-bottom: 6px;
  padding-right: 12%;
  line-height: 22px;
}

@media (min-width: 768px) {
  #about .about--kybella-box-top .button {
    margin-bottom: var(--emu-semantic-spacing-vertical);
  }
}

#about .about--kybella-box-bottom .aaaem-text p {
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-semantic-colors-secondary-light-grey-400);
  margin: var(--emu-common-spacing-none);
  letter-spacing: .9px;
  padding-top: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

@media (min-width: 768px) {
  #about .about--kybella-box-top > .aem-Grid, #about .about--kybella-box-bottom > .aem-Grid {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    display: flex;
  }

  #about .about--kybella-box-top {
    border-bottom: 1px solid var(--emu-common-colors-white);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #about .about--kybella-box-image-desktop {
    display: none;
  }

  #about .about--kybella-box-image-tablet {
    padding-top: 25px;
    display: block;
  }

  #about .about--kybella-box-top {
    width: 100%;
  }

  #about .about--kybella-box-top ul {
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  #about .about--kybella-box-top ul li {
    padding-right: var(--emu-common-spacing-none);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) and (min-width: 768px) and (max-width: 800px) {
  #about .about--kybella-box-top ul li {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

@media screen and (max-width: 767px) {
  #about .about--kybella-box {
    padding: 30px 10px 30px 20px;
  }

  #about .about--kybella-box-image-desktop {
    border-bottom: 1px solid var(--emu-common-colors-white);
    margin-top: 35px;
    display: block;
  }

  #about .about--kybella-box-image-tablet {
    display: none;
  }

  #about .about--kybella-box-top {
    width: 100%;
  }

  #about .about--kybella-box-top .cmp-title__text {
    font-size: 18px;
  }

  #about .about--kybella-box-top ul li {
    padding-bottom: 10px;
    padding-right: 12%;
  }

  #about .about--kybella-box-bottom {
    padding-top: 10px;
  }
}

#about .allure--container-logo {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  #about .allure--container-logo {
    width: 160px;
  }
}

#about .video-container--about-kyb {
  margin-top: 5px;
  padding: 30px;
}

#about .video-container--about-kyb .embed {
  width: 100%;
}

#about .how--kyb-works {
  padding-top: 30px;
}

#about .how--kyb-works > .aem-Grid {
  flex-direction: column-reverse;
  display: flex;
}

#about .how--kyb-works__content-container {
  width: 100%;
}

#about .how--kyb-works__content-container .aaaem-text p {
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  margin-bottom: 30px;
  padding-right: 10%;
  line-height: 20px;
}

#about .parent-container--expect-with-kyb {
  margin-top: 2px;
}

#about .expect-title .cmp-title__text {
  margin-top: 25px;
  margin-bottom: 25px;
}

#about .expect-paragraph p {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: 1px;
  width: 80%;
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  margin: auto;
}

@media (min-width: 768px) {
  #about .expect-title .cmp-title__text {
    margin-bottom: 10px;
  }

  #about .expect-paragraph p {
    width: 88%;
  }
}

@media (min-width: 992px) {
  #about .expect-title .cmp-title__text {
    margin: var(--emu-common-spacing-none);
    padding-top: 35px;
    padding-bottom: 25px;
  }

  #about .expect-paragraph p {
    width: 80%;
  }
}

#about .kybella-administered__container > .aem-Grid {
  flex-direction: column-reverse;
  display: flex;
}

#about .kybella-administered__image-container .aaaem-image {
  width: 450px;
  max-width: 100%;
}

#about .kybella-administered__content {
  margin-left: var(--emu-semantic-spacing-horizontal);
  margin-bottom: 10px;
}

#about .kybella-administered__content .aaaem-title {
  padding-top: 30px;
}

#about .kybella-administered__content .text {
  width: 100%;
}

#about .kybella-administered__content .text .aaaem-text:not(.title--medium) {
  padding-top: var(--emu-semantic-spacing-horizontal);
  padding-right: 25px;
}

#about .kybella-administered__content .text .aaaem-text:not(.title--medium) p {
  letter-spacing: .9px;
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
}

#about .tabs-container--kyb-treatment-session {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

#about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid {
  flex-direction: column-reverse;
  display: flex;
}

#about .tabs-container--kyb-treatment-session__first-sec .container {
  width: 100%;
  margin-left: var(--emu-common-spacing-none);
}

#about .tabs-container--kyb-treatment-session__first-sec .tabs {
  width: 100%;
}

#about .tabs-container--kyb-treatment-session__first-sec .tabs .aaaem-tabs__tablist {
  margin-left: var(--emu-semantic-spacing-horizontal);
  justify-content: flex-start;
}

#about .tabs-container--kyb-treatment-session__first-sec .text .aaaem-text {
  margin-top: 20px;
  margin-bottom: var(--emu-semantic-spacing-horizontal);
}

#about .tabs-container--kyb-treatment-session__first-sec .text .aaaem-text p {
  letter-spacing: 1px;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid {
  flex-direction: column;
  display: flex;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid:nth-child(2) .text {
  width: 100%;
  margin-left: var(--emu-semantic-spacing-horizontal);
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .tab-text--small p {
  font-size: 10px;
  font-family: var(--emu-semantic-font-families-mono);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  letter-spacing: .75px;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container {
  width: 100%;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container .aaaem-container .title .aaaem-title {
  margin-top: 25px;
  margin-bottom: 10px;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container .aaaem-container .text {
  margin-left: var(--emu-common-spacing-none);
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container .aaaem-container .text .aaaem-text {
  margin-bottom: 10px;
}

#about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container .aaaem-container .text .aaaem-text p {
  line-height: var(--emu-semantic-line-heights-narrow-xl);
}

@media (min-width: 768px) {
  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container .aaaem-container .text .aaaem-text.tab-text--small p {
    line-height: 14px;
  }
}

#about .tabs-container--kyb-treatment-session .tab-bottom-text--discription-long {
  display: none;
}

#about .tabs-container--kyb-treatment-session .tab-bottom-text--discription-sort {
  display: block;
}

#about .time-recover-container {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

#about .time-recover-container .cmp-title__text {
  letter-spacing: 3px;
}

#about .time-recover-container .aaaem-text p {
  margin: var(--emu-common-spacing-none);
  padding-top: var(--emu-semantic-spacing-vertical);
  letter-spacing: .9px;
  color: var(--emu-semantic-colors-secondary-dark-grey-100);
  padding-right: 25px;
}

#about .kybella-side-effects__container {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
  flex-direction: column;
  display: flex;
}

#about .kybella-side-effects__serious {
  margin-bottom: var(--emu-semantic-spacing-vertical);
}

#about .kybella-side-effects__serious .cmp-title__text {
  letter-spacing: 3px;
}

#about .kybella-side-effects__serious .cmp-text p {
  margin-top: 20px;
}

#about .kybella-side-effects__serious .cmp-text p b {
  color: var(--emu-common-colors-black);
}

#about .kybella-side-effects__serious .cmp-text ul {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

#about .kybella-side-effects__serious .cmp-text ul li {
  letter-spacing: .9px;
  padding-top: 5px;
}

#about .kybella-side-effects__common .aem-Grid .title .aaaem-title {
  margin-top: 25px;
  margin-bottom: 10px;
}

#about .kybella-side-effects__common .aem-Grid .title .aaaem-title h2 {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  letter-spacing: 2.1px;
}

#about .kybella-side-effects__common .aem-Grid .text .aaaem-text {
  margin-top: 20px;
  margin-bottom: 5px;
}

#about .about-kybella-note-container {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-bottom: 20px;
}

#about .about-kybella-note-container .cmp-title__text {
  letter-spacing: 3px;
  margin-bottom: var(--emu-semantic-spacing-vertical);
}

#about .about-kybella-note-container .cmp-text p {
  letter-spacing: .9px;
  margin-bottom: var(--emu-semantic-spacing-horizontal);
}

#about .about-kybella-note-container .cmp-text ul {
  margin: var(--emu-common-spacing-none);
  padding-left: 25px;
}

#about .about-kybella-note-container .cmp-text ul li {
  letter-spacing: 1px;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  padding-top: 10px;
}

@media (min-width: 768px) {
  #about .about-kybella-note-container {
    padding: var(--emu-common-spacing-none);
    padding-left: 30px;
    padding-right: var(--emu-semantic-spacing-horizontal);
  }

  #about .about-kybella-note-container .cmp-text ul {
    margin-top: 10px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 992px) {
  #about .about-kybella-note-container {
    padding: var(--emu-common-spacing-none);
    padding-left: 20px;
    padding-right: 20px;
  }

  #about .about-kybella-note-container .cmp-title__text {
    letter-spacing: 3px;
  }

  #about .about-kybella-note-container .cmp-text ul {
    margin-top: 10px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

@media (min-width: 768px) {
  #about .tabs--about-kyb .aaaem-tabs__tablist .aaaem-tabs__tab a {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    width: 104px;
  }

  #about .how--kyb-works > .aem-Grid {
    flex-direction: row;
  }

  #about .how--kyb-works .how--kyb-works__image-container {
    width: 324px;
  }

  #about .how--kyb-works .how--kyb-works__image-container .aaaem-image {
    margin-right: var(--emu-semantic-spacing-horizontal);
  }

  #about .how--kyb-works .how--kyb-works__content-container > .aem-Grid {
    margin-left: var(--emu-semantic-spacing-horizontal);
  }

  #about .kybella-administered__container > .aem-Grid {
    flex-direction: row;
  }

  #about .kybella-administered__content {
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  #about .kybella-administered__content .title .aaaem-title {
    padding-top: var(--emu-common-spacing-none);
  }

  #about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid {
    flex-direction: row;
  }

  #about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid .tabs {
    padding-left: 10px;
    padding-right: 5px;
  }

  #about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid .container .title {
    padding-left: 20px;
  }

  #about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid .container .text .aaaem-text {
    margin-top: var(--emu-common-spacing-none);
    padding-left: 20px;
    padding-right: 20px;
  }

  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid {
    flex-direction: row;
    align-items: center;
    margin-top: -20px;
  }

  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container {
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: 5px;
  }

  #about .time-recover-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  #about .kybella-side-effects__container {
    flex-direction: row;
  }

  #about .kybella-side-effects__serious {
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  #about .kybella-side-effects__serious .title .aaaem-title {
    padding-right: var(--emu-semantic-spacing-horizontal);
  }

  #about .kybella-side-effects__serious .cmp-text p {
    font-size: 16px;
    line-height: var(--emu-semantic-line-heights-wide-xl);
    margin-top: 20px;
  }

  #about .kybella-side-effects__common {
    padding-left: 20px;
    padding-right: 25px;
  }

  #about .kybella-side-effects__common .text .aaaem-text {
    font-size: 16px;
    line-height: var(--emu-semantic-line-heights-wide-xl);
  }

  #about .about-kybella-note-container .container .cmp-text {
    width: 285px;
    padding-bottom: 20px;
  }

  #about .emu-navigation__content-wrapper nav > ul > li:nth-child(3) > span {
    color: var(--emu-semantic-colors-primary-red-100);
  }
}

@media (min-width: 992px) {
  #about .tabs--about-kyb .aaaem-tabs__tablist .aaaem-tabs__tab a {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    width: 126px;
  }

  #about .heading--about-kyb .text .aaaem-text {
    width: 65%;
  }

  #about .how--kyb-works .how--kyb-works__image-container {
    width: 435px;
    height: 237px;
  }

  #about .kybella-administered__content {
    padding-top: 35px;
    padding-left: 20px;
  }

  #about .tabs-container--kyb-treatment-session__first-sec > .aem-Grid .tabs {
    padding-left: 25px;
    padding-right: 45px;
  }

  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid {
    margin-top: var(--emu-common-spacing-none);
  }

  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .container {
    padding-left: 25px;
    padding-right: 10px;
  }

  #about .tabs-container--kyb-treatment-session__last-sec > .aem-Grid .tab-text--small {
    padding-left: var(--emu-semantic-spacing-horizontal);
  }

  #about .tabs-container--kyb-treatment-session .tab-bottom-text--discription-long {
    display: block;
  }

  #about .tabs-container--kyb-treatment-session .tab-bottom-text--discription-sort {
    display: none;
  }

  #about .kybella-side-effects__serious .cmp-text p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }

  #about .kybella-side-effects__common .text .aaaem-text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
    line-height: var(--emu-semantic-line-heights-wide-large);
  }

  #about .about-kybella-note-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  #about .about-kybella-note-container .container .cmp-text {
    max-width: 405px;
    width: 100%;
  }
}

#before-after .tab-gallery--before-after {
  position: relative;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner {
  height: auto !important;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content {
  position: relative;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .text .aaaem-text {
  padding-top: var(--emu-semantic-spacing-vertical);
  padding-right: var(--emu-common-spacing-none);
  padding-bottom: 20px;
  padding-left: var(--emu-semantic-spacing-horizontal);
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .text .aaaem-text h4 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  letter-spacing: 3px;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .text .aaaem-text p {
  letter-spacing: 1px;
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  margin-top: -10px;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tabs .aaaem-tabs {
  margin: var(--emu-common-spacing-none);
  flex-direction: column-reverse;
  display: flex;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tabs .aaaem-tabs__tabpanels .aaaem-tabs__tabpanel {
  border-bottom: var(--emu-semantic-border-width-thin) solid var(--emu-common-colors-white);
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tabs .aaaem-tabs__tablist {
  justify-content: center;
  padding-top: 15vw;
  display: flex;
  position: relative;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tabs .aaaem-tabs__tablist:before {
  content: "ROTATION";
  color: var(--emu-common-colors-white);
  font-family: var(--emu-semantic-font-families-mono);
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 6vw;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text {
  width: 100%;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 13vw;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text .text {
  width: 28vw;
  text-align: center;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text .text .aaaem-text {
  padding: var(--emu-common-spacing-none);
  margin: auto;
  font-size: 8px;
}

#before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text .text .aaaem-text p {
  line-height: 10px;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators {
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-left: var(--emu-semantic-spacing-horizontal);
  margin-top: var(--emu-common-spacing-none);
  background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
  justify-content: flex-start;
  display: flex;
  position: static;
  overflow-x: auto;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators .aaaem-carousel__image-indicator {
  opacity: .5;
  width: 60px;
  border: none;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators .aaaem-carousel__image-indicator:hover {
  opacity: 1;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators .aaaem-carousel__image-indicator:hover .aaaem-carousel__image-indicator-name {
  background-color: var(--emu-semantic-colors-primary-red-200);
  bottom: var(--emu-common-spacing-none);
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
  position: absolute;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators .aaaem-carousel__image-indicator img {
  min-width: 60px;
  min-height: 60px;
}

#before-after .tab-gallery--before-after .aaaem-carousel__indicators .tns-nav-active {
  opacity: 1;
}

#before-after .text-bottom--tab-gallery {
  padding-bottom: 5px;
}

#before-after .text-bottom--tab-gallery .aem-Grid {
  flex-direction: column;
  display: flex;
}

#before-after .text-bottom--tab-gallery .aem-Grid .container {
  width: 100%;
}

#before-after .text-bottom--tab-gallery .aem-Grid .container .aaaem-container .text .aaaem-text {
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-left: var(--emu-semantic-spacing-horizontal);
  font-size: 10px;
}

#before-after .text-bottom--tab-gallery .aem-Grid .container .aaaem-container .text .aaaem-text p {
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-semantic-colors-secondary-dark-grey-600);
  margin-bottom: 10px;
  line-height: 14px;
}

#before-after .text-container--real-stories .title .aaaem-title {
  font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  letter-spacing: 3.6px;
  text-align: center;
  padding-top: 30px;
}

#before-after .text-container--real-stories .text .aaaem-text {
  letter-spacing: 1px;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  line-height: var(--emu-semantic-line-heights-narrow-xl);
  width: 79%;
  text-align: center;
  margin: 20px auto auto;
  padding-bottom: 20px;
}

#before-after .gallery-component--hear-what-patients .title {
  width: 80%;
  margin: auto;
}

#before-after .gallery-component--hear-what-patients .title .aaaem-title {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

#before-after .text-large--in-clinical-studies {
  padding-top: 10px;
  padding-bottom: 10px;
}

#before-after .text-large--in-clinical-studies__left-sec {
  border-bottom: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-actions-primary-dark);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
  align-items: flex-start;
  display: flex;
}

#before-after .text-large--in-clinical-studies__left-sec .text--large {
  margin-top: -15px;
  font-size: 50px;
  line-height: 70px;
}

#before-after .text-large--in-clinical-studies__left-sec .text--large-left {
  width: 160px;
}

#before-after .text-large--in-clinical-studies__left-sec .text--large-left .text .aaaem-text p {
  text-align: center;
}

#before-after .text-large--in-clinical-studies__left-sec .text--medium-left .text .aaaem-text p {
  letter-spacing: 0;
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  line-height: 22px;
}

#before-after .text-large--in-clinical-studies__right-sec {
  width: 100%;
}

#before-after .text-large--in-clinical-studies__right-sec .text .aaaem-text {
  margin-left: var(--emu-semantic-spacing-horizontal);
  margin-right: var(--emu-semantic-spacing-horizontal);
  padding-top: var(--emu-semantic-spacing-vertical);
  padding-bottom: 20px;
}

#before-after .text-large--in-clinical-studies__right-sec .text .aaaem-text p {
  letter-spacing: 0;
}

@media (min-width: 390px) {
  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tabs .aaaem-tabs .aaaem-tabs__tablist {
    padding-top: 12vw;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text {
    bottom: 12vw;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .tab-gallery--bottom-sticky-text .text .aaaem-text {
    line-height: var(--emu-semantic-line-heights-narrow-xl);
  }
}

@media (min-width: 768px) {
  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-container {
    border-bottom: 1px solid var(--emu-common-colors-white);
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 33px;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content {
    position: relative;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container {
    align-items: center;
    display: flex;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .text .aaaem-text {
    padding: var(--emu-common-spacing-none);
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tabpanels .aaaem-tabs__tabpanel {
    border-bottom: var(--emu-semantic-outline-width-unset);
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tabpanels .aaaem-tabs__tabpanel .image .aaaem-image img {
    height: 167px;
    margin-left: 13px;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tablist {
    padding-top: var(--emu-common-spacing-none);
    position: absolute;
    bottom: 0;
    left: 28%;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tablist:before {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    bottom: -3%;
    left: -25%;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tab-gallery--bottom-sticky-text {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: var(--emu-semantic-outline-width-unset);
    bottom: -5%;
    left: 30%;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tab-gallery--bottom-sticky-text .text {
    width: 154px;
  }

  #before-after .tab-gallery--before-after .aaaem-carousel__indicators {
    justify-content: flex-start;
    padding-left: 30px;
  }

  #before-after .text-bottom--tab-gallery {
    margin-left: var(--emu-semantic-spacing-horizontal);
    margin-right: var(--emu-semantic-spacing-horizontal);
    padding-bottom: 20px;
  }

  #before-after .text-bottom--tab-gallery .aem-Grid {
    flex-direction: row;
  }

  #before-after .text-bottom--tab-gallery .aem-Grid .aaaem-container .aem-Grid {
    flex-direction: column;
    display: flex;
  }

  #before-after .text-container--real-stories .title .aaaem-title {
    padding-top: 20px;
  }

  #before-after .text-large--in-clinical-studies {
    display: flex;
  }

  #before-after .text-large--in-clinical-studies__left-sec {
    border-bottom: var(--emu-semantic-outline-width-unset);
  }

  #before-after .text-large--in-clinical-studies__left-sec .text--medium-left {
    border-right: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-actions-primary-dark);
    padding-right: 30px;
  }

  #before-after .text-large--in-clinical-studies__left-sec .text--large {
    margin-top: -20px;
    font-size: 75px;
    line-height: 105px;
  }

  #before-after .text-large--in-clinical-studies__right-sec {
    width: 250px;
  }

  #before-after .text-large--in-clinical-studies__right-sec .text .aaaem-text {
    padding-left: 10px;
    padding-right: var(--emu-semantic-spacing-horizontal);
  }

  #before-after .emu-navigation__content-wrapper nav > ul > li:nth-child(5) > span {
    color: var(--emu-semantic-colors-primary-red-100);
  }
}

@media (min-width: 992px) {
  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-container {
    margin-top: 50px;
    margin-left: 55px;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tabpanels .aaaem-tabs__tabpanel .image .aaaem-image img {
    height: 217px;
    margin-left: 66px;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tabs .aaaem-tabs__tablist {
    left: 32%;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tab-gallery--bottom-sticky-text {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: none;
    bottom: -5%;
    left: 33%;
  }

  #before-after .tab-gallery--before-after .tns-outer .tns-inner .aaaem-carousel__content .aaaem-carousel__item .aaaem-container .tab-gallery--bottom-sticky-text .text {
    width: 190px;
  }

  #before-after .tab-gallery--before-after .aaaem-carousel__indicators {
    justify-content: center;
  }

  #before-after .text-bottom--tab-gallery {
    margin-left: 45px;
    margin-right: 30px;
    padding-bottom: 30px;
  }

  #before-after .gallery-component--hear-what-patients .title {
    width: 64%;
  }

  #before-after .text-large--in-clinical-studies__left-sec .text--medium-left {
    padding-left: 38px;
  }

  #before-after .text-large--in-clinical-studies__left-sec .text--large {
    margin-top: -20px;
    font-size: 65px;
    line-height: 91px;
  }

  #before-after .text-large--in-clinical-studies__right-sec {
    width: 300px;
  }
}

#before-after .cmp-container-header {
  border: none;
}

#faq .faq__title {
  margin-top: 30px;
  margin-bottom: 10px;
}

#faq .faq__title .cmp-title__text {
  letter-spacing: 4.05px;
  font-size: 27px;
  line-height: 30px;
}

@media (min-width: 768px) {
  #faq .faq__title .cmp-title__text {
    letter-spacing: 3.6px;
    font-size: 24px;
  }
}

#faq .faq__desc {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #faq .faq__desc {
    margin-bottom: 25px;
  }
}

#savings .savings__title .cmp-title__text {
  margin-top: 20px;
}

@media (min-width: 992px) {
  #savings .savings__title .cmp-title__text {
    margin-top: 25px;
  }
}

#savings .savings__desc p {
  letter-spacing: 1.05px;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  #savings .savings__desc p {
    margin-bottom: 20px;
  }
}

#savings .savings-box {
  position: relative;
}

#savings .savings-box--image-mobile {
  height: 400px;
  overflow: hidden;
}

#savings .savings-box--image-mobile img {
  width: 100%;
  height: auto;
}

#savings .savings-box--image-tablet {
  height: 407px;
  overflow: hidden;
}

#savings .savings-box--image-tablet img {
  height: 100%;
  width: 100%;
}

#savings .savings-box--image-desktop {
  height: 330px;
  overflow: hidden;
}

#savings .savings-box--image-desktop img {
  width: 100%;
  height: auto;
}

#savings .savings-box--content-container {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  #savings .savings-box--content-container {
    background-color: var(--emu-semantic-colors-none);
    width: 37%;
    top: var(--emu-common-spacing-none);
    right: var(--emu-common-spacing-none);
    padding-top: var(--emu-common-spacing-none);
    padding-left: var(--emu-semantic-spacing-horizontal);
    margin-right: 27px;
    position: absolute;
  }
}

@media (min-width: 992px) {
  #savings .savings-box--content-container {
    padding-top: 20px;
  }
}

#savings .savings-box--content-container .aaaem-title h5 {
  letter-spacing: 2.7px;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: normal;
  line-height: 26px;
}

#savings .savings-box--content-container .aaaem-text p {
  letter-spacing: .9px;
  padding-right: var(--emu-semantic-spacing-horizontal);
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;
}

@media (min-width: 768px) {
  #savings .savings-box--content-container .aaaem-text p {
    padding-right: 25px;
    font-size: 12px;
  }
}

#savings .savings-box--content-container .aaaem-button {
  color: var(--emu-semantic-colors-primary-red-100);
  border-color: var(--emu-semantic-colors-primary-red-100);
}

#savings .savings-box--content-container .aaaem-button:hover {
  color: var(--emu-semantic-colors-primary-red-100);
  background-color: var(--emu-semantic-colors-primary-red-400);
  border-color: var(--emu-semantic-colors-primary-red-100);
}

#savings .savings-alle__container {
  padding-top: 10px;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: var(--emu-semantic-spacing-horizontal);
}

#savings .savings-alle__container .container.responsivegrid {
  width: 100%;
}

@media (min-width: 768px) {
  #savings .savings-alle__container {
    padding-right: var(--emu-semantic-spacing-none);
    padding-left: var(--emu-semantic-spacing-none);
    padding-bottom: 25px;
  }
}

@media (min-width: 992px) {
  #savings .savings-alle__container {
    padding-left: 40px;
    padding-right: 40px;
  }
}

#savings .savings-alle__content-container {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

#savings .savings-alle__content-container > .container {
  position: relative;
}

#savings .savings-alle__content-container > .container .aaaem-container {
  margin-bottom: 50px;
}

#savings .savings-alle__content-container > .container:before {
  content: "";
  width: 1px;
  height: 40px;
  background-color: var(--emu-semantic-colors-secondary-light-grey-600);
  position: absolute;
  top: -50px;
  left: 50%;
}

#savings .savings-alle__content-container > .container:first-child:before {
  display: none;
}

#savings .savings-alle__content-container > .container:last-child .aaaem-container {
  margin-bottom: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  #savings .savings-alle__content-container > .container:before {
    display: none;
  }

  #savings .savings-alle__content-container > .container:after {
    content: "";
    width: 70%;
    height: 1px;
    background-color: var(--emu-semantic-colors-secondary-light-grey-600);
    position: absolute;
    top: 36px;
    left: 100%;
    transform: translateX(-50%);
  }

  #savings .savings-alle__content-container > .container:last-child:after {
    display: none;
  }

  #savings .savings-alle__content-container > .container .aaaem-container {
    margin-bottom: var(--emu-semantic-spacing-none);
  }

  #savings .savings-alle__content-container {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

#savings .savings-alle__title .cmp-title__text {
  margin-top: var(--emu-semantic-spacing-horizontal);
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  #savings .savings-alle__title .cmp-title__text {
    margin-top: 35px;
    margin-bottom: 50px;
  }
}

#savings .savings-alle__number {
  width: 70px;
  margin-left: auto;
  margin-right: auto;
}

#savings .savings-alle__number p {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 22px 20px 24px 22px;
  font-size: 36px;
}

#savings .savings-alle__desc p {
  letter-spacing: 1.05px;
  padding-top: 10px;
}

#savings .savings-alle__desc-small p {
  letter-spacing: .9px;
  padding-top: var(--emu-semantic-spacing-none);
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-bottom: 10px;
  padding-left: var(--emu-semantic-spacing-horizontal);
}

#savings .savings-get-the-app {
  position: relative;
}

#savings .savings-get-the-app__content-container {
  padding: 10px 20px 20px;
}

@media (min-width: 768px) {
  #savings .savings-get-the-app__content-container {
    padding-top: 30px;
    padding-left: 58.3%;
    padding-right: 10px;
  }
}

#savings .savings-get-the-app__content-container .cmp-title__text {
  letter-spacing: .9px;
}

@media (min-width: 992px) {
  #savings .savings-get-the-app__content-container .cmp-title__text {
    line-height: 38px;
  }
}

#savings .savings-get-the-app__content-container .aaaem-text {
  margin: var(--emu-semantic-spacing-none);
}

#savings .savings-get-the-app__content-container .aaaem-text p {
  letter-spacing: .9px;
  margin-top: 10px;
}

#savings .savings-get-the-app__content-container .aaaem-text ul {
  margin: var(--emu-semantic-spacing-none);
  padding-left: 20px;
}

#savings .savings-get-the-app__content-container .aaaem-text ul li {
  letter-spacing: .9px;
  margin-top: var(--emu-semantic-spacing-horizontal);
}

#savings .savings-get-the-app__content-container .button {
  z-index: 1;
}

#savings .savings-get-the-app__content-container .button .aaaem-button {
  margin-top: 39px;
}

#savings .savings-get-the-app__image-container {
  position: relative;
}

@media (min-width: 768px) {
  #savings .savings-get-the-app__image-container {
    height: 425px;
  }
}

#savings .savings-get-the-app__image-container img {
  width: 95%;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  #savings .savings-get-the-app__image-container img {
    width: 100%;
    z-index: 0;
    position: absolute;
    top: -125px;
  }
}

@media (min-width: 992px) {
  #savings .savings-get-the-app__image-container img {
    width: 80%;
    top: -175px;
  }
}

#savings .savings-benefits__container {
  padding: var(--emu-semantic-spacing-none);
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  #savings .savings-benefits__container {
    padding-top: 20px;
  }
}

#savings .savings-benefits__title .cmp-title__text {
  margin-top: 30px;
}

#savings .savings-benefits__desc {
  margin-top: 20px;
  margin-bottom: 20px;
}

#savings .savings-benefits__content-container {
  padding: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  #savings .savings-benefits__content-container {
    padding-top: var(--emu-semantic-spacing-none);
    padding-right: 75px;
    padding-bottom: var(--emu-semantic-spacing-none);
    padding-left: 75px;
  }
}

#savings .savings-benefits__content-container .button, #savings .savings-benefits__content-container .image {
  justify-content: center;
  display: flex;
}

#savings .savings-benefits__content-container .aaaem-image {
  width: 100px;
  height: 70px;
}

#savings .savings-benefits__content-container .aaaem-image img {
  height: 100%;
  margin-top: var(--emu-semantic-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-semantic-spacing-none);
  margin-left: auto;
}

#savings .savings-benefits__content-title .cmp-title__text {
  padding-top: 25px;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 768px) {
  #savings .savings-benefits__content-title .cmp-title__text {
    font-size: 20px;
    line-height: 28px;
  }
}

#savings .savings-benefits__content-desc {
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  #savings .savings-benefits__content-desc {
    padding-bottom: var(--emu-semantic-spacing-none);
  }
}

#savings .savings-benefits__content-desc p {
  font-size: 15px;
  line-height: 21px;
}

@media (min-width: 768px) {
  #savings .savings-benefits__content-desc p {
    letter-spacing: .9px;
    font-size: 12px;
    line-height: 17px;
  }
}

#savings .savings-benefits__content-button {
  color: var(--emu-semantic-colors-primary-red-200);
  border-color: var(--emu-semantic-colors-primary-red-200);
}

#savings .savings-benefits__content-button:hover {
  background-color: var(--emu-semantic-colors-primary-red-400);
}

body.modal_on#kyb-fap-page .navbar-default, body.modal_on#kyb-fap-page #ISIFloating {
  z-index: var(--emu-common-other-z-index-base);
}

body.modal_on#kyb-fap-page #locator_root {
  z-index: inherit;
}

#kyb-fap-page .kyb-fap {
  display: none;
}

#kyb-fap-page .cmp-container-header {
  border-bottom: none;
}

#kyb-fap-page #locator_root {
  max-width: var(--emu-semantic-sizing-breakpoints-x-large);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) and (max-width: 991px) {
  #kyb-fap-page #locator_root {
    margin-top: 170px;
  }
}

#kyb-fap-page #locator_root .provider-search__search-form .provider-search__search-form--outer-input {
  background-color: var(--emu-semantic-colors-secondary-light-grey-1100);
}

#kyb-fap-page #locator_root .provider-search__search-form .button span.text {
  margin-top: 0;
}

@media (min-width: 1200px) {
  #kyb-fap-page #locator_root .search-sort-container {
    grid-template-columns: .7fr 10px 1fr;
  }
}

#kyb-fap-page #locator_root #sortList ul li label.cb-container {
  padding-left: 20px;
  font-size: 12px;
  font-weight: normal;
}

#kyb-fap-page #locator_root .consultation-request-form__inline-form__form button.button__theme-dark {
  border: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-dark-grey-700);
  background-color: #0000;
}

#kyb-fap-page #locator_root .consultation-request-form__inline-form__form button.button__theme-dark span.text {
  margin-top: var(--emu-semantic-sizing-none);
  color: var(--emu-semantic-colors-secondary-dark-grey-700);
}

@media (min-width: 768px) and (max-width: 991px) {
  #kyb-fap-page #locator_root .providers-results-page .provider-search__search-results {
    margin-left: 20px;
  }
}

@media (min-width: 89.9875em) {
  #kyb-fap-page #locator_root .providers-results-page .provider-search__search-form .location-input {
    padding-left: 42px;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #kyb-fap-page #locator_root #sort_slider input[type="range"] {
    top: -17px;
  }

  #kyb-fap-page #locator_root .provider-profile-page .provider-profile-content-container #treatment-list ul li {
    height: 3.5em;
    margin-bottom: 10px;
    font-size: .8em;
  }
}

#kyb-fap-page #locator_root .modal_isi .isi_content h2, #kyb-fap-page #locator_root .modal_isi .isi_content p {
  color: #222;
}

#kyb-fap-page #locator_root #filters, #kyb-fap-page #locator_root .lightbox-background .fap-modal {
  font-size: 14px;
}

#kyb-fap-page #locator_root #filters .sub-text, #kyb-fap-page #locator_root .lightbox-background .fap-modal .sub-text {
  font-family: var(--emu-semantic-font-families-mono);
}

#kyb-fap-page #locator_root #filters h2, #kyb-fap-page #locator_root .lightbox-background .fap-modal h2 {
  margin-top: 30px;
  margin-bottom: 10px;
}

#kyb-fap-page #locator_root #filters p, #kyb-fap-page #locator_root .lightbox-background .fap-modal p {
  margin-bottom: 10px;
}

#kyb-fap-page #locator_root .provider-search-filter-modal__content__form__fields__field .range_background {
  line-height: 1.65;
}

#kyb-fap-page #locator_root .fap-modal #hours-container li span {
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
}

#kyb-fap-page #locator_root .legal-disclosure--dark a {
  color: #00e;
}

#kyb-fap-page #locator_root #sortList ul li label.cb-container {
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
}

.aaaem-accordion__title {
  letter-spacing: 3px;
  color: var(--emu-semantic-colors-secondary-blue-100);
  padding-left: 38px;
  position: relative;
}

.aaaem-accordion__title:before {
  content: "Q";
  border: 1px solid var(--emu-semantic-colors-secondary-blue-100);
  border-radius: 50%;
  padding: 1px 5px 2px 8px;
  font-size: 18px;
  position: absolute;
  left: -5px;
}

@media (min-width: 768px) {
  .aaaem-accordion__title {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    line-height: 45px;
  }

  .aaaem-accordion__title:before {
    margin-right: 10px;
    padding: 1px 3px 2px 6px;
    position: relative;
    left: 0;
  }
}

.aaaem-accordion__header {
  padding-top: 20px;
}

@media (min-width: 768px) {
  .aaaem-accordion__header {
    padding-top: var(--emu-semantic-spacing-horizontal);
    padding-bottom: 5px;
  }
}

@media (min-width: 992px) {
  .aaaem-accordion__header {
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .aaaem-accordion__header.-ba-is-active {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__title:before {
  content: "A";
  border-color: var(--emu-semantic-colors-primary-red-200);
  color: var(--emu-semantic-colors-primary-red-200);
}

@media (min-width: 768px) {
  .aaaem-accordion__header.-ba-is-active .aaaem-accordion__title:before {
    margin-right: 10px;
    padding: 1px 4px 3px 7px;
    position: relative;
  }
}

.aaaem-accordion__icon {
  align-self: flex-start;
}

@media (min-width: 768px) {
  .aaaem-accordion__icon {
    align-self: center;
  }
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner {
  border-bottom: var(--emu-component-lists-accordion-item-border-width-bottom-open) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
  padding-bottom: 24px;
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text {
  margin: var(--emu-common-spacing-none);
  letter-spacing: 1px;
  line-height: 20px;
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text p, .aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text li {
  color: var(--emu-component-lists-accordion-color-text-light);
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text a {
  color: var(--emu-semantic-colors-primary-red-100);
  letter-spacing: 1.8px;
  text-decoration: none;
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text p {
  padding-bottom: 10px;
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text ul {
  padding-left: var(--emu-semantic-spacing-horizontal);
}

.aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner .aaaem-text ul li::marker {
  color: var(--emu-semantic-colors-primary-red-200);
}

@media (min-width: 992px) {
  .aaaem-accordion.js-badger-accordion dd.aaaem-accordion__panel .js-badger-accordion-panel-inner {
    padding-right: 115px;
  }
}

.aaaem-button {
  letter-spacing: 2px;
}

.aaaem-card header {
  display: none;
}

.aaaem-card__body .title .cmp-title__text {
  letter-spacing: 2.5px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  font-size: 16px;
  line-height: 22px;
}

.aaaem-card footer {
  text-align: left;
  padding: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-semantic-spacing-vertical);
  margin-left: 20px;
  margin-right: 20px;
}

.aaaem-card footer .aaaem-container {
  padding: var(--emu-common-spacing-none);
  flex-direction: column;
  display: flex;
}

@media (min-width: 992px) {
  .aaaem-card footer .aaaem-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.aaaem-card footer .cmp-text p {
  letter-spacing: 1px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .aaaem-card footer .cmp-text p {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) {
  .aaaem-card footer .cmp-text p {
    margin-bottom: 10px;
  }
}

.kyb-card-container > .aem-Grid {
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .kyb-card-container > .aem-Grid {
    flex-direction: row;
  }

  .kyb-card-container > .aem-Grid .card {
    flex: 1;
  }

  .kyb-card-container > .aem-Grid .card .aaaem-card {
    margin-right: 3px;
  }

  .kyb-card-container > .aem-Grid .card:last-child .aaaem-card {
    margin-right: var(--emu-common-spacing-none);
  }
}

.aaaem-carousel {
  background-color: var(--emu-semantic-colors-secondary-light-grey-100);
}

.aaaem-carousel .tns-outer [data-action] {
  display: none;
}

.aaaem-carousel__indicators {
  position: relative;
  bottom: 10px;
  left: 0;
}

@media (min-width: 768px) {
  .aaaem-carousel {
    background-color: var(--emu-semantic-colors-none);
  }

  .aaaem-carousel__indicators {
    position: absolute;
    bottom: 20px;
    left: 47%;
  }

  .aaaem-carousel__indicator {
    border-color: var(--emu-semantic-colors-secondary-light-grey-600);
    background-color: var(--emu-semantic-colors-none);
  }

  .aaaem-carousel__indicator.tns-nav-active {
    background-color: var(--emu-semantic-colors-secondary-light-grey-600);
    border-color: var(--emu-semantic-colors-secondary-light-grey-600);
  }
}

[data-component="container-isi-v2"].js-content-shown .isi-container {
  opacity: 0;
  pointer-events: none;
  transform: none;
}

[data-component="container-isi-v2"] .isi-container {
  background-color: #ffffffe6;
  transition: opacity 1s;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .isi-container {
    background-color: var(--emu-component-containers-isi-header-color-background-light);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container {
  bottom: 55px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content h3, [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
  font-family: var(--emu-semantic-font-families-mono);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  letter-spacing: 0;
  line-height: 19px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content h3 b, [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p b {
  font-weight: var(--emu-semantic-font-weight-bold);
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content h3 {
  width: 80%;
  margin-bottom: var(--emu-common-spacing-none);
  font-weight: var(--emu-semantic-font-weight-bold);
  color: var(--emu-semantic-colors-secondary-dark-grey-700);
  margin-top: 10px;
  line-height: 1;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
  margin-top: 5px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper {
  top: 0;
  right: var(--emu-semantic-spacing-horizontal);
  position: absolute;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper .aaaem-button {
  padding-top: var(--emu-common-spacing-xs);
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xs);
  padding-left: var(--emu-common-spacing-small);
  background-color: var(--emu-semantic-colors-primary-red-100);
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-mono);
  letter-spacing: 1px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper .aaaem-button:after {
  content: "+";
  font-family: var(--emu-semantic-font-families-body);
  margin-left: 10px;
  font-size: 20px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content {
  background-color: var(--emu-semantic-colors-secondary-light-grey-200);
  padding-bottom: 30px;
  position: relative;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .button {
  position: absolute;
  top: 0;
  right: 0;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .button .aaaem-button {
  padding-top: var(--emu-common-spacing-xs);
  padding-right: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-xs);
  padding-left: var(--emu-common-spacing-small);
  background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
  color: var(--emu-common-colors-white);
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-mono);
  letter-spacing: 1px;
  text-decoration: none;
  display: block;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .button .aaaem-button:after {
  content: "—";
  font-size: 18px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text {
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  font-family: var(--emu-semantic-font-families-mono);
  padding-top: var(--emu-semantic-spacing-horizontal);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text h3 {
  font-weight: var(--emu-semantic-font-weight-bold);
  width: 70%;
  color: var(--emu-semantic-colors-secondary-dark-grey-700);
  font-family: inherit;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text p {
  letter-spacing: 0;
  font-family: inherit;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text p a {
  color: var(--emu-semantic-colors-secondary-blue-200);
  text-decoration: none;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text p b {
  font-weight: var(--emu-semantic-font-weight-bold);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text ul {
  padding-left: var(--emu-semantic-spacing-horizontal);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content .aaaem-text ul li {
  letter-spacing: 0;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-container {
    background-color: var(--emu-semantic-colors-secondary-light-grey-200);
    bottom: 0;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper .aaaem-button {
    background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
  }

  [data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-banner {
    max-width: var(--emu-semantic-sizing-breakpoints-medium);
    margin: auto;
    position: relative;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content div {
    max-width: var(--emu-semantic-sizing-breakpoints-medium);
    padding-top: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-semantic-spacing-horizontal);
    padding-left: var(--emu-common-spacing-none);
    margin: auto;
    position: relative;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content div .button {
    right: var(--emu-common-spacing-none);
    padding: var(--emu-common-spacing-none);
  }
}

@media (min-width: 990px) {
  [data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-banner, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content div {
    max-width: var(--emu-semantic-sizing-breakpoints-large);
    margin: auto;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content div .button {
    right: 0;
  }
}

.cmp-experiencefragment--container-isi-v2 {
  margin-top: 10px;
}

.kyb-fap {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);
}

.kyb-fap > .container {
  background-color: var(--emu-semantic-colors-secondary-light-grey-900);
  padding-top: 12px;
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-bottom: 12px;
  padding-left: var(--emu-semantic-spacing-horizontal);
}

.kyb-fap .image, .kyb-fap__close.cmp-button, .kyb-fap__req-text, .kyb-fap__url-text {
  display: none;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form, .kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text label {
  color: var(--emu-semantic-colors-primary-red-200);
  letter-spacing: 1.33px;
  flex: 1;
  font-size: 14px;
  line-height: 18px;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text input {
  width: 50%;
  min-width: 90px;
  margin-right: var(--emu-common-spacing-small);
  border: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-light-grey-1000);
  text-transform: uppercase;
  color: var(--emu-semantic-colors-secondary-dark-grey-800);
  padding: 9px 12px;
  font-size: 14px;
  line-height: 20px;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text input.error {
  border-color: var(--emu-semantic-colors-secondary-red-100);
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text input::placeholder {
  color: var(--emu-semantic-colors-secondary-light-grey-500);
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form > .button {
  width: 50%;
  max-width: 110px;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form > .button button {
  width: 100%;
  text-align: center;
  color: var(--emu-semantic-colors-secondary-light-grey-600);
  background-color: var(--emu-semantic-colors-primary-red-100);
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 11px;
  line-height: 16px;
  display: block;
}

.kyb-fap__form-wrapper.cmp-container form.cmp-form .text {
  flex: 1;
}

@media (min-width: 768px) {
  .kyb-fap {
    z-index: var(--emu-common-other-z-index-layer);
    height: 218px;
    padding: var(--emu-semantic-spacing-none);
    transition: all .3s linear;
    display: flex;
    position: fixed;
    top: 172px;
    right: 0;
    transform: translateX(100%)translateX(-48px);
  }

  .kyb-fap.kyb-fap--shown {
    transform: translateX(0);
  }

  .kyb-fap > .container {
    padding: var(--emu-semantic-spacing-none);
    background-color: var(--emu-semantic-colors-secondary-light-grey-100);
  }

  .kyb-fap .image, .kyb-fap__close.cmp-button, .kyb-fap__req-text {
    display: block;
  }

  .kyb-fap .kyb-fap__req-text {
    letter-spacing: normal;
  }

  .kyb-fap label {
    display: none;
  }

  .kyb-fap .image .cmp-image {
    height: 100%;
    cursor: pointer;
  }

  .kyb-fap .image .cmp-image img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .kyb-fap__form-wrapper.cmp-container {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .kyb-fap__form-wrapper.cmp-container > .button {
    margin-bottom: var(--emu-semantic-spacing-vertical);
    text-align: right;
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close {
    background-color: var(--emu-semantic-colors-none);
    border-color: var(--emu-semantic-colors-none);
    margin-left: auto;
    margin-right: 15px;
    padding: 0;
    display: inline-block;
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close:focus, .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close:active, .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close:hover {
    outline: none;
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close span {
    padding: 10px;
    display: block;
    position: relative;
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close span:after, .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close span:before {
    content: "";
    width: 2px;
    height: 100%;
    transform-origin: center;
    background-color: var(--emu-semantic-colors-secondary-dark-grey-500);
    position: absolute;
    top: 0;
    left: 10px;
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close span:before {
    transform: rotate(45deg);
  }

  .kyb-fap__form-wrapper.cmp-container > .button .kyb-fap__close span:after {
    transform: rotate(-45deg);
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form {
    max-width: 190px;
    display: block;
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text {
    display: block;
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form .cmp-form-text input {
    width: 100%;
    border-color: var(--emu-semantic-colors-primary-red-100);
    text-align: center;
    height: 45px;
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form .text, .kyb-fap__form-wrapper.cmp-container form.cmp-form .button {
    max-width: 146px;
    width: 100%;
    margin-bottom: var(--emu-semantic-spacing-vertical);
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form .button button {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    text-transform: none;
    text-transform: initial;
    letter-spacing: normal;
    border: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-light-grey-1000);
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .kyb-fap {
    top: 135px;
  }

  .kyb-fap__form-wrapper.cmp-container {
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-top: 10px;
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form {
    max-width: 175px;
  }

  .kyb-fap__form-wrapper.cmp-container form.cmp-form .text, .kyb-fap__form-wrapper.cmp-container form.cmp-form .button {
    max-width: 165px;
  }
}

.kyb-footer__main-wrapper.aaaem-container {
  padding-top: 25px;
  padding-bottom: var(--emu-semantic-spacing-vertical);
}

.kyb-footer__top-section {
  border-bottom: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-light-grey-800);
  justify-content: space-between;
  display: flex;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul {
  margin: var(--emu-common-spacing-none);
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li {
  margin-bottom: 20px;
  list-style: none;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li .emphasis {
  grid-gap: 6px;
  align-items: center;
  gap: 6px;
  display: flex;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li .emphasis img {
  height: 12px;
  width: auto;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li .emphasis a {
  padding-right: var(--emu-common-spacing-none);
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li a {
  color: var(--emu-common-colors-white);
  font-family: nimbus-sans-condensed, sans-serif;
  line-height: 10px;
  text-decoration: none;
  display: block;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text ul li a:hover {
  background-color: var(--emu-semantic-colors-secondary-light-grey-500);
  color: var(--emu-semantic-colors-secondary-dark-grey-500);
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text p {
  margin-top: 50px;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text p a {
  margin-right: 25px;
  text-decoration: none;
}

.kyb-footer__top-section .aaaem-container .text .aaaem-text p a img {
  max-width: 31px;
}

.kyb-footer__links-left-section {
  max-width: 182px;
}

.kyb-footer__bottom-section .image {
  padding-right: var(--emu-semantic-spacing-horizontal);
  margin-top: 20px;
  margin-bottom: 20px;
}

.kyb-footer__bottom-section .image .aaaem-image {
  max-width: 111px;
}

.kyb-footer__bottom-section .image .aaaem-image img {
  width: 100%;
}

.kyb-footer__bottom-section .text .aaaem-text {
  letter-spacing: 0;
  color: var(--emu-common-colors-white);
}

.kyb-footer__bottom-section .text .aaaem-text p {
  margin-bottom: 25px;
  font-family: nimbus-sans-condensed, sans-serif;
}

@media (min-width: 768px) {
  .kyb-footer__main-wrapper.aaaem-container {
    padding-bottom: var(--emu-common-spacing-none);
  }

  .kyb-footer__top-section {
    flex-direction: column;
  }

  .kyb-footer__top-section .aaaem-container .text .aaaem-text ul li {
    margin-bottom: var(--emu-common-spacing-none);
  }

  .kyb-footer__top-section .aaaem-container .text .aaaem-text ul li a {
    line-height: 1.25;
  }

  .kyb-footer__top-section .aaaem-container .text .aaaem-text p {
    text-align: center;
    margin-top: 20px;
  }

  .kyb-footer__top-section .aaaem-container .text .aaaem-text p a {
    margin-right: 12px;
  }

  .kyb-footer__links-left-section {
    width: 100%;
    padding-left: var(--emu-semantic-spacing-horizontal);
    grid-gap: 70px;
    gap: 70px;
  }

  .kyb-footer__links-left-section .text {
    max-width: 300px;
  }

  .kyb-footer__links-left-section, .kyb-footer__links-right-section {
    display: flex;
  }

  .kyb-footer__links-left-section .aaaem-container, .kyb-footer__links-right-section .aaaem-container {
    grid-gap: 70px;
    gap: 70px;
  }

  .kyb-footer__links-left-section .text .aaaem-text ul li a, .kyb-footer__links-right-section .text .aaaem-text ul li a {
    padding-right: var(--emu-semantic-spacing-horizontal);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .kyb-footer__bottom-section {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .kyb-footer__bottom-section .text .aaaem-text {
    width: 90%;
  }

  .kyb-footer__bottom-section .text .aaaem-text p {
    margin-top: 20px;
    margin-bottom: var(--emu-common-spacing-none);
  }

  .kyb-footer__bottom-section .image {
    padding-left: var(--emu-semantic-spacing-horizontal);
  }
}

@media (min-width: 992px) {
  .kyb-footer__bottom-section .text .aaaem-text {
    width: 100%;
  }
}

.emu-gallery {
  border-top: none;
  border-left: none;
  border-right: none;
}

@media (min-width: 992px) {
  .emu-gallery {
    padding-top: var(--emu-component-containers-gallery-padding-top-wide);
    padding-right: var(--emu-component-containers-gallery-padding-right-wide);
    padding-bottom: var(--emu-component-containers-gallery-padding-bottom-wide);
    padding-left: var(--emu-component-containers-gallery-padding-left-wide);
    margin-top: var(--emu-component-containers-gallery-margin-top-wide);
    margin-right: var(--emu-component-containers-gallery-margin-right-wide);
    margin-bottom: var(--emu-component-containers-gallery-margin-bottom-wide);
    margin-left: var(--emu-component-containers-gallery-margin-left-wide);
  }
}

.emu-gallery__preview-item {
  border: none;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__stage-item-wrapper {
    flex-flow: wrap;
    display: flex;
  }

  .emu-gallery .gallery__stage-item-wrapper .embed {
    flex: 1.5;
  }

  .emu-gallery .gallery__stage-item-wrapper .container {
    flex: 1;
  }
}

.emu-gallery .gallery__stage-item-text-wrapper {
  border-top: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-primary-red-200);
  padding-top: 40px;
  padding-left: 37px;
  position: relative;
}

.emu-gallery .gallery__stage-item-text-wrapper:after {
  content: "";
  height: 145px;
  width: 155px;
  background-image: url("resources/images/quote.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 10px;
  left: 10px;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__stage-item-text-wrapper {
    max-width: 87%;
    padding-top: 20px;
    position: relative;
    left: 28px;
  }

  .emu-gallery .gallery__stage-item-text-wrapper:after {
    top: 0;
    left: -30px;
  }
}

@media (min-width: 992px) {
  .emu-gallery .gallery__stage-item-text-wrapper {
    padding-top: 40px;
    padding-left: 20px;
    left: 60px;
  }

  .emu-gallery .gallery__stage-item-text-wrapper:after {
    top: 20px;
    left: -40px;
  }
}

.emu-gallery .gallery__stage-item-text {
  letter-spacing: 1.275px;
  color: var(--emu-semantic-colors-secondary-dark-grey-400);
  padding-right: 25px;
  font-size: 17px;
  line-height: 36px;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__stage-item-text {
    padding-bottom: 25px;
    padding-right: 23px;
    font-size: 20px;
    line-height: 24px;
  }
}

@media (min-width: 992px) {
  .emu-gallery .gallery__stage-item-text {
    letter-spacing: 2.1px;
    padding-bottom: 5px;
    font-size: 28px;
    line-height: 36px;
  }
}

.emu-gallery .gallery__stage-item-subtext {
  letter-spacing: .2px;
  font-size: 16px;
  position: relative;
}

.emu-gallery .gallery__stage-item-subtext p {
  text-align: right;
}

.emu-gallery .gallery__stage-item-subtext:before {
  content: "";
  background-color: var(--emu-semantic-colors-primary-red-200);
  height: 1px;
  width: 100%;
  max-width: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__stage-item-subtext:before {
    max-width: 110px;
  }
}

@media (min-width: 992px) {
  .emu-gallery .gallery__stage-item-subtext:before {
    max-width: 160px;
  }
}

.emu-gallery .gallery__preview-item-wrapper {
  background-color: var(--emu-semantic-colors-secondary-light-grey-200);
  flex-direction: row;
  display: flex;
}

.emu-gallery .gallery__preview-item-wrapper .image {
  max-width: 130px;
  padding-left: 10px;
  padding-right: 10px;
}

.emu-gallery .gallery__preview-item-text p {
  letter-spacing: .65px;
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  padding-bottom: 20px;
  font-size: 11px;
  line-height: 15.4px;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__preview-item-text p {
    padding-right: var(--emu-semantic-spacing-none);
    padding-left: var(--emu-semantic-spacing-none);
    font-size: 9px;
    line-height: 12px;
  }
}

@media (min-width: 992px) {
  .emu-gallery .gallery__preview-item-text p {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: var(--emu-semantic-spacing-vertical);
    font-size: 11px;
    line-height: 15.4px;
  }
}

.emu-gallery .gallery__preview-item-subtext {
  letter-spacing: .75px;
  color: var(--emu-semantic-colors-secondary-dark-grey-200);
  grid-gap: 8px;
  align-items: flex-end;
  gap: 8px;
  font-size: 10px;
  line-height: 14px;
  display: flex;
}

.emu-gallery .gallery__preview-item-subtext:before {
  content: "";
  background-color: var(--emu-semantic-colors-primary-red-200);
  height: 1px;
  width: 92px;
  display: block;
  position: relative;
  top: -4px;
}

@media (min-width: 768px) {
  .emu-gallery .gallery__preview-item-subtext:before {
    width: 40px;
  }
}

@media (min-width: 992px) {
  .emu-gallery .gallery__preview-item-subtext:before {
    width: 92px;
  }
}

header.experiencefragment {
  height: 95px;
}

@media (min-width: 768px) {
  header.experiencefragment {
    height: 170px;
  }
}

@media (min-width: 992px) {
  header.experiencefragment {
    height: 140px;
  }
}

@media (min-width: 768px) {
  .has-header-fixed .header .cmp-container-header {
    border: none;
    top: 0;
  }

  .has-header-fixed .header__eyebrow {
    transform: translateY(-100%);
  }
}

.header {
  width: 100%;
  z-index: var(--emu-common-other-z-index-header);
  background-color: var(--emu-semantic-colors-secondary-light-grey-600);
  position: fixed;
  top: 0;
  left: 0;
}

.header sup {
  font-size: 10px;
  line-height: 8px;
}

@media (min-width: 768px) {
  .header__eyebrow {
    z-index: 501;
    width: 100%;
    background-color: #e6e6e6;
    position: fixed;
    top: 0;
  }

  .header__eyebrow-main {
    justify-content: center;
    display: flex;
  }

  .header__eyebrow-main > .text:last-child .header__eyebrow-link {
    border: none;
  }

  .header__eyebrow-link {
    font-size: 8px;
    line-height: var(--emu-semantic-line-heights-narrow-xl);
    color: #525154;
    border-right: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-light-grey-600);
  }
}

@media (min-width: 768px) and (min-width: 1012px) {
  .header__eyebrow-link {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
  }
}

@media (min-width: 768px) {
  .header__eyebrow-link.aaaem-text p {
    font-family: var(--emu-common-font-families-sans);
    letter-spacing: normal;
  }

  .header__eyebrow-link.aaaem-text a {
    padding-top: 5px;
    padding-left: 3px;
    padding-right: 3px;
    text-decoration: none;
    display: block;
  }

  .header__eyebrow-link.aaaem-text a:hover {
    background-color: #eee;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .header__eyebrow-link.aaaem-text a {
    padding-top: 3px;
    padding-right: var(--emu-semantic-spacing-horizontal);
    padding-bottom: var(--emu-semantic-spacing-none);
    padding-left: var(--emu-semantic-spacing-horizontal);
  }
}

@media (min-width: 768px) {
  .header__eyebrow-link.aaaem-text.header__eyebrow-link--active a {
    color: var(--emu-semantic-colors-secondary-light-grey-600);
  }

  .header__eyebrow-link.aaaem-text.header__eyebrow-link--active a:hover {
    color: var(--emu-semantic-colors-secondary-light-grey-600);
    background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
  }

  .header__eyebrow-link .emphasis {
    font-size: 11px;
    line-height: 1;
    font-weight: var(--emu-semantic-font-weight-bold);
    display: block;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  .header__eyebrow-link .emphasis {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
  }
}

@media (min-width: 768px) {
  .header__eyebrow-link--active {
    color: var(--emu-semantic-colors-secondary-dark-grey-600);
  }

  .header__eyebrow-link--active a {
    background-color: var(--emu-semantic-colors-secondary-dark-grey-400);
  }
}

.header .cmp-container-header {
  padding-top: 30px;
  padding-right: var(--emu-semantic-spacing-horizontal);
  padding-bottom: 10px;
  padding-left: var(--emu-semantic-spacing-horizontal);
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .header .cmp-container-header {
    max-width: var(--emu-semantic-sizing-breakpoints-medium);
    padding-top: 10px;
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    background-color: var(--emu-semantic-colors-secondary-light-grey-600);
    border-bottom: var(--emu-semantic-border-width-thin) solid var(--emu-semantic-colors-secondary-light-grey-800);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
    position: relative;
    top: 36px;
  }

  .header .cmp-container-header:after {
    content: "";
    background-color: inherit;
    z-index: var(--emu-common-other-z-index-behind);
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 992px) {
  .header .cmp-container-header {
    max-width: 990px;
    padding-bottom: var(--emu-semantic-spacing-vertical);
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    top: 39px;
  }
}

.header .cmp-container-header .header__logo {
  min-width: 182px;
  max-width: 50%;
}

.header .cmp-container-header .header__logo img, .header .cmp-container-header .header__logo a {
  width: 100%;
  max-width: 182px;
  display: block;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__logo {
    min-width: 265px;
  }

  .header .cmp-container-header .header__logo img, .header .cmp-container-header .header__logo a {
    max-width: none;
  }
}

.header .cmp-container-header .header__global-navigation {
  display: none;
}

.header .cmp-container-header .header__content {
  margin-left: auto;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content {
    margin-left: var(--emu-semantic-spacing-none);
    width: 100%;
  }
}

.header .cmp-container-header .header__content > .text {
  width: 100%;
  background-color: #edeef0;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content > .text {
    background-color: var(--emu-semantic-colors-none);
    top: -3px;
  }
}

.header .cmp-container-header .header__content > .text .aaaem-text {
  color: #7b7b7b;
  font-size: 7px;
  line-height: 10px;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content > .text .aaaem-text {
    color: var(--emu-semantic-colors-secondary-dark-grey-400);
    padding-top: 8px;
    padding-bottom: 5px;
    font-family: nimbus-sans-condensed, sans-serif;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .header .cmp-container-header .header__content > .text .aaaem-text {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

.header .cmp-container-header .header__content > .text ul {
  justify-content: center;
  list-style: none;
  display: flex;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content > .text ul {
    width: 100%;
    max-width: 768px;
    margin-top: var(--emu-semantic-spacing-none);
    margin-right: auto;
    margin-bottom: var(--emu-semantic-spacing-none);
    padding-left: var(--emu-semantic-spacing-horizontal);
    padding-right: var(--emu-semantic-spacing-horizontal);
    justify-content: flex-end;
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .header .cmp-container-header .header__content > .text ul {
    max-width: var(--emu-semantic-sizing-breakpoints-large);
  }
}

.header .cmp-container-header .header__content > .text ul li {
  letter-spacing: normal;
}

.header .cmp-container-header .header__content > .text ul li:not(:last-child) {
  border-right: var(--emu-semantic-border-width-thin) solid #d3d3d3;
  margin-right: 4px;
  padding-right: 4px;
}

.header .cmp-container-header .header__content > .text ul li:nth-child(3) {
  display: none;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content > .text ul li:nth-child(3) {
    display: block;
  }
}

.header .cmp-container-header .header__content > .text ul li a {
  letter-spacing: 0;
  text-decoration: none;
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content > .text ul li a {
    text-transform: uppercase;
    letter-spacing: normal;
  }

  .header .cmp-container-header .header__content > .text ul li a:hover {
    color: #7b7b7b;
  }
}

.header .cmp-container-header .header__content .button .aaaem-button {
  width: 34px;
  height: 24px;
  padding: var(--emu-semantic-spacing-none);
  display: block;
  position: relative;
}

.header .cmp-container-header .header__content .button .aaaem-button:hover, .header .cmp-container-header .header__content .button .aaaem-button:active, .header .cmp-container-header .header__content .button .aaaem-button:focus {
  background-color: var(--emu-semantic-colors-none);
  border-color: var(--emu-semantic-colors-none);
  outline: none;
}

.header .cmp-container-header .header__content .button .aaaem-button span:not(.chevron) {
  height: 3px;
  background-color: var(--emu-semantic-colors-secondary-blue-100);
  width: 100%;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

.header .cmp-container-header .header__content .button .aaaem-button span:not(.chevron):after, .header .cmp-container-header .header__content .button .aaaem-button span:not(.chevron):before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--emu-semantic-colors-secondary-blue-100);
  display: block;
  position: absolute;
  left: 0;
}

.header .cmp-container-header .header__content .button .aaaem-button span:not(.chevron):before {
  top: -7px;
}

.header .cmp-container-header .header__content .button .aaaem-button span:not(.chevron):after {
  top: 7px;
}

.header .cmp-container-header .header__content .button .aaaem-button.js-toggle-on span:not(.chevron) {
  background-color: #0000;
}

.header .cmp-container-header .header__content .button .aaaem-button.js-toggle-on span:not(.chevron):after {
  top: 4px;
  left: 4px;
  transform: rotate(45deg);
}

.header .cmp-container-header .header__content .button .aaaem-button.js-toggle-on span:not(.chevron):before {
  transform-origin: 100%;
  transform: rotate(-45deg);
}

@media (min-width: 768px) {
  .header .cmp-container-header .header__content .button {
    display: none;
  }
}

.header__nav-wrapper {
  width: 100%;
  max-height: var(--emu-semantic-sizing-none);
  transition: all .6s linear;
  position: absolute;
  top: 85px;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.header__nav-wrapper.js-toggle-on {
  max-height: calc(100vh - 85px);
}

@media (min-width: 768px) {
  .header__nav-wrapper.js-toggle-on {
    max-height: none;
  }

  .header__nav-wrapper {
    max-height: none;
    position: static;
    overflow: visible;
  }
}

.emu-navigation__content-wrapper {
  position: static;
}

.emu-navigation__content-wrapper nav a {
  letter-spacing: 1.2px;
  font-family: nimbus-sans-condensed, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav a {
    font-family: var(--emu-semantic-font-families-body);
    font-size: var(--emu-semantic-font-sizes-wide-large);
    letter-spacing: 1.8px;
    line-height: 20px;
  }
}

@media (min-width: 992px) {
  .emu-navigation__content-wrapper nav a {
    letter-spacing: 1.65px;
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav > ul {
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .emu-navigation__content-wrapper nav > ul {
    justify-content: flex-end;
    position: relative;
    top: 10px;
  }
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav > ul > li > a, .emu-navigation__content-wrapper nav > ul > li > span > a {
    white-space: nowrap;
  }

  .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent {
    position: relative;
  }

  .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent:hover {
    background-color: #ebebeb;
  }

  .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent ul .emu-navigation__item--active a {
    color: var(--emu-component-lists-navigation-dropdown-item-text-color-default-light);
  }

  .emu-navigation__content-wrapper nav > ul > li:hover > a, .emu-navigation__content-wrapper nav > ul > li:hover > span {
    color: var(--emu-semantic-colors-primary-red-100);
  }
}

.emu-navigation__content-wrapper nav > ul > li > a {
  border-right: var(--emu-common-border-width-none);
  border-left: var(--emu-common-border-width-none);
  border-bottom: var(--emu-common-border-width-none);
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav > ul > li > a {
    border-top: var(--emu-common-border-width-none);
  }

  .emu-navigation__content-wrapper nav > ul > li > a, .emu-navigation__content-wrapper nav > ul > li > span {
    border: var(--emu-common-border-width-none);
  }

  .emu-navigation__content-wrapper nav > ul > li > a:hover, .emu-navigation__content-wrapper nav > ul > li > span:hover {
    color: var(--emu-semantic-colors-primary-red-200);
  }

  .emu-navigation__content-wrapper nav > ul > li > span {
    padding: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 992px) {
  .emu-navigation__content-wrapper nav > ul > li > a, .emu-navigation__content-wrapper nav > ul > li > span > a {
    padding-top: var(--emu-common-spacing-small);
    padding-right: 11px;
    padding-bottom: var(--emu-common-spacing-small);
    padding-left: 11px;
  }
}

.emu-navigation__content-wrapper nav > ul > li ul {
  min-width: 140px;
  width: 100%;
}

.emu-navigation__content-wrapper nav > ul > li ul a {
  padding-right: var(--emu-component-lists-navigation-text-padding-left-wide);
  letter-spacing: normal;
  padding-left: 10px;
}

.emu-navigation__content-wrapper nav > ul > li:nth-last-child(2) > a, .emu-navigation__content-wrapper nav > ul > li:last-child > a {
  color: #a4a4a4;
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav > ul > li:nth-last-child(2), .emu-navigation__content-wrapper nav > ul > li:last-child {
    display: none;
  }

  .emu-navigation__content-wrapper nav > ul > li:nth-last-child(3) a {
    padding-right: var(--emu-common-spacing-xs);
  }
}

@media (min-width: 992px) {
  .emu-navigation__content-wrapper nav > ul > li:nth-last-child(3) a {
    padding-right: var(--emu-semantic-spacing-none);
  }
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav .emu-navigation__item.emu-navigation__item--active > a {
    color: var(--emu-semantic-colors-primary-red-100);
  }

  .emu-navigation__item:nth-child(2), .emu-navigation__item:nth-child(4) {
    display: none;
  }
}

.emu-navigation__item-parent {
  display: none;
}

@media (min-width: 768px) {
  .emu-navigation__item-parent, .emu-navigation__item-parent > ul > li.emu-navigation__item {
    display: block;
  }
}

.separator .aaaem-separator {
  width: 94%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
}

@media (min-width: 768px) {
  .separator .aaaem-separator {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
  }
}

.tabs .aaaem-tabs__tablist .aaaem-tabs__tab {
  line-height: 12px;
}

@media (min-width: 768px) {
  .tabs .aaaem-tabs__tablist .aaaem-tabs__tab {
    margin-left: 27px;
    margin-right: 27px;
  }
}

@media (min-width: 768px) {
  .aaaem-teaser .content-container {
    position: absolute;
    top: 0;
  }
}

.aaaem-teaser .content-container .disclaimer p {
  margin: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-mono);
  letter-spacing: .75px;
  line-height: normal;
}

.aaaem-teaser .content-container .aaaem-teaser__title {
  max-width: 94%;
  letter-spacing: normal;
  color: var(--emu-semantic-colors-secondary-dark-grey-100);
  margin-bottom: 10px;
  line-height: normal;
}

@media (min-width: 768px) {
  .aaaem-teaser .content-container .aaaem-teaser__title {
    letter-spacing: 3px;
    color: var(--emu-semantic-colors-secondary-light-grey-400);
  }
}

.aaaem-teaser .content-container .aaaem-teaser__action-link {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  letter-spacing: 2px;
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-red-200);
  padding: 12px 30px;
  text-decoration: none;
}

.aaaem-teaser .content-container .aaaem-teaser__action-link:hover {
  background-color: var(--emu-semantic-colors-primary-red-300);
}

.aaaem-teaser .content-container .aaaem-teaser__action-link:hover span {
  color: var(--emu-semantic-colors-secondary-light-grey-600);
}

@media (min-width: 768px) {
  .aaaem-teaser .content-container .aaaem-teaser__action-link {
    padding: 9px 18px;
  }
}

@media (min-width: 992px) {
  .aaaem-teaser .content-container .aaaem-teaser__action-link {
    padding: 12px 30px;
  }
}

.aaaem-teaser .content-container .cmp-teaser__action-link-text {
  color: var(--emu-semantic-colors-primary-red-200);
  text-decoration: none;
}

@media (min-width: 768px) {
  .aaaem-teaser .content-container .cmp-teaser__action-link-text {
    color: var(--emu-semantic-colors-secondary-light-grey-600);
  }
}

.aaaem-teaser .content-container .cmp-teaser__description {
  color: var(--emu-semantic-colors-secondary-dark-grey-100);
  margin-bottom: 10px;
}

.aaaem-teaser .content-container .cmp-teaser__description p {
  letter-spacing: .9px;
  margin-right: -15px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .aaaem-teaser .content-container .cmp-teaser__description {
    color: var(--emu-semantic-colors-secondary-light-grey-400);
  }
}

.aaaem-text {
  letter-spacing: 1.2px;
}

.aaaem-text b {
  font-weight: var(--emu-common-font-weight-bold);
}

.aaaem-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

.aaaem-text ul, .aaaem-text li {
  margin-left: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
}

.aaaem-text h1, .aaaem-text h2, .aaaem-text h3, .aaaem-text h4, .aaaem-text h5, .aaaem-text h6, .aaaem-text p, .aaaem-text li, .aaaem-text a, .aaaem-text b, .aaaem-text i, .aaaem-text pre, .aaaem-text sup, .aaaem-text sub, .aaaem-text u, .aaaem-text pre, .aaaem-text th, .aaaem-text td, .aaaem-text span {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.aaaem-text sup, .aaaem-text.title--large h1 sup, .aaaem-text.title--large h2 sup, .aaaem-text.title--large h3 sup, .aaaem-text.title--medium h1 sup, .aaaem-text.title--medium h2 sup, .aaaem-text.title--medium h3 sup, .aaaem-text.title--small h1 sup, .aaaem-text.title--small h2 sup, .aaaem-text.title--small h3 sup {
  font-size: 10px;
  line-height: 8px;
}

.aaaem-text.title--large h1 {
  letter-spacing: 4px;
  font-size: 27px;
  line-height: 30px;
}

.aaaem-text.title--large h1 sup {
  font-size: 12px;
}

.aaaem-text.title--medium h2, .aaaem-text.title--medium h3 {
  letter-spacing: 3px;
  font-size: 20px;
  line-height: 28px;
}

.aaaem-text.title--small h2, .aaaem-text.title--small h3 {
  letter-spacing: 3px;
  font-size: 18px;
  line-height: 28px;
}

.aaaem-text.title--xsmall h2, .aaaem-text.title--xsmall h3 {
  letter-spacing: 2.5px;
  font-size: 16px;
  line-height: 23px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  letter-spacing: 3px;
}

.aaaem-title h1, .aaaem-title h2, .aaaem-title h3, .aaaem-title h4, .aaaem-title h5, .aaaem-title h6 {
  color: inherit;
  letter-spacing: 3px;
}

.aaaem-title h1 {
  letter-spacing: 3.6px;
}

.aaaem-title__color-primary {
  color: var(--emu-semantic-colors-primary-red-100);
}

.aaaem-title__color-secondary {
  color: var(--emu-semantic-colors-secondary-blue-100);
}

.aaaem-title__color-white {
  color: var(--emu-common-colors-white);
}

.aaaem-title__color-black {
  color: var(--emu-common-colors-black);
}

/*# sourceMappingURL=main.css.map */
