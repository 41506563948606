// common custom made up component's css across different pages

// Bazaar logo container on home and about pages
.image-centric-container {
  &.aaaem-container {
    > .aem-Grid {
      .image {
        .aaaem-image {
          width: 140px;

          img {
            display: block;
          }

          @include mq('medium') {
            width: 160px;
          }
        }
      }
    }
  }
}

// visual result large text container styles - About smf and before after pages.
.common__visual-results {
  padding-top: 10px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;

  .fifth-sec {
    &__left-section {
      .text--large {
        font-size: 32px;
        line-height: 45px;
        p {
          font-weight: var(--emu-semantic-font-weight-bold);
        }
      }

      .text--medium {
        font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
        line-height: 34px;
        padding-bottom: var(--emu-semantic-spacing-vertical);
        margin-bottom: 20px;
        border-bottom: 1px solid var(--emu-semantic-colors-primary-red-200);
      }
    }

    &__right-section {
      .aaaem-text {
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
      }
    }
  }

  @include mq('medium') {
    padding-right: 10px;
    > .aem-Grid {
      display: flex;
      margin-top: 20px;
      align-items: center;

      > .container {
        &:first-child {
          width: 38%;
          margin-right: 35px;
        }

        &:last-child {
          flex: 1;
        }
      }
    }

    .fifth-sec {
      &__left-section {
        border-right: 1px solid var(--emu-semantic-colors-primary-red-200);
        .text--large {
          font-size: 18px;
          line-height: 25px;
        }

        .text--medium {
          border-bottom: none;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 0;
        }
      }
    }
  }

  @include mq($emu-semantic-sizing-breakpoints-x-large) {
    .fifth-sec {
      &__left-section {
        .text--large {
          font-size: 32px;
          line-height: 45px;
        }

        .text--medium {
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          line-height: 34px;
          padding-bottom: var(--emu-common-spacing-none);
        }
      }
    }
  }
}

// common table for about smf and before-after kyb pages
.common-treatment-chart__table {
  padding-left: var(--emu-semantic-spacing-horizontal);
  padding-right: var(--emu-semantic-spacing-horizontal);

  &-header {
    padding-top: var(--emu-semantic-spacing-vertical);
    padding-right: 10px;
    padding-bottom: var(--emu-semantic-spacing-vertical);
    padding-left: 10px;

    &.aaaem-text {
      p {
        font-weight: var(--emu-common-font-weight-bold);
        font-size: 15px;
        font-weight: var(--emu-common-font-weight-bold);
      }
    }
  }

  &-elem {
    max-width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      border-top: none;
      border-collapse: collapse;
      background-color: var(--emu-semantic-colors-secondary-light-grey-600);

      tr,
      td,
      th {
        border: var(--emu-semantic-border-width-thin) solid
          var(--emu-semantic-colors-secondary-dark-grey-600);
        color: var(--emu-semantic-colors-secondary-dark-grey-400);
        text-align: center;
        font-weight: normal;
      }

      sup {
        font-size: 6px;
      }
    }

    &--endpoint {
      margin-bottom: 30px;
      tr {
        &:first-child,
        &:nth-child(2) {
          th,
          td {
            font-size: var(--emu-semantic-font-sizes-narrow-medium);
            line-height: var(--emu-semantic-line-heights-narrow-medium);
            letter-spacing: 0.6px;
            font-weight: bold;
          }
        }

        &:last-child,
        &:nth-child(3) {
          td {
            &:not(:first-child) {
              color: var(--emu-semantic-colors-primary-red-100);
            }
          }
        }

        td:first-child {
          text-align: left;
        }
      }
    }

    &--treatment-details {
      margin-top: 30px;
      table {
        tr {
          &:first-child {
            th,
            td {
              font-size: var(--emu-semantic-font-sizes-narrow-medium);
              line-height: var(--emu-semantic-line-heights-narrow-medium);
              letter-spacing: 0.6px;
              font-weight: bold;
            }
          }

          &:nth-child(2),
          &:nth-child(4) {
            td {
              &:not(:nth-child(1)):not(:nth-child(2)) {
                color: var(--emu-semantic-colors-primary-red-100);
              }

              &:nth-child(1),
              &:nth-child(2) {
                text-align: left;
              }

              &:nth-child(2) {
                font-size: 9px;
                font-weight: bold;
              }
            }
          }

          &:nth-child(3),
          &:nth-child(5) {
            td {
              &:not(:nth-child(1)) {
                color: var(--emu-semantic-colors-primary-red-100);
              }

              &:nth-child(1) {
                text-align: left;
                font-size: 9px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  &-disclaimers {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 10px;
    line-height: 14px;
    color: var(--emu-semantic-colors-secondary-dark-grey-200);

    // increasing specificity
    &.cmp-text {
      p {
        letter-spacing: 0.75px;
        line-height: 14px;

        @include mq('medium') {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: normal;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          letter-spacing: 0;
          font-size: 10px;
          line-height: 14px;
        }
      }

      p,
      sup {
        font-family: var(--emu-semantic-font-families-mono);

        @include mq('medium') {
          font-family: var(--emu-semantic-font-families-heading);
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-family: var(--emu-semantic-font-families-mono);
        }
      }

      sup {
        font-size: 5px;

        @include mq('medium') {
          font-size: 7px;
        }

        @include mq($emu-semantic-sizing-breakpoints-x-large) {
          font-size: 5px;
        }
      }
    }
  }
}

// Common Card component related css across different pages
.kyb-card-container {
  .card {
    > div,
    .aaaem-card {
      &__body {
        .title {
          .cmp-title__text {
            font-size: 15px;
            line-height: 21px;
            max-width: 65%;
          }
        }
      }

      .button {
        padding-top: 29px;
        padding-bottom: 9px;
      }

      margin-bottom: 3px;

      @include mq(medium) {
        height: 100%;
        position: relative;
        margin-bottom: var(--emu-common-spacing-none);

        &__body {
          .title {
            .cmp-title__text {
              letter-spacing: 0.75px;
              max-width: 85%;
            }
          }
        }

        footer {
          padding-bottom: 68px;
          .aaaem-container {
            flex-direction: column;
            align-items: flex-start;
          }
          .cmp-text p {
            font-size: 13px;
            letter-spacing: 0.26px;
          }
        }

        .button {
          position: static;
          padding: var(--emu-common-spacing-none);
        }

        .aaaem-button {
          position: absolute;
          left: 20px;
          bottom: 20px;
        }
        margin-bottom: var(--emu-common-spacing-none);
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        &__body {
          .title {
            .cmp-title__text {
              max-width: 65%;
            }
          }
        }

        footer {
          .cmp-text p {
            font-size: 12px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

// bringing the captcha on top of everything else on the poge, and making sure that it does not block other elements
.g-recaptcha {
  position: fixed;
  z-index: var(--emu-common-other-z-index-modal);
  display: block;
  top: 40px;
}

// when remediation banner is present, make sure that the page is shifted
body.r-banner-open {
  .header__eyebrow,
  header.header {
    top: 47px;
    z-index: var(--emu-common-other-z-index-header);
  }

  header.header {
    background-color: var(--emu-common-colors-transparent);
  }
}
