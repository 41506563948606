.aaaem-accordion {
  &__title {
    letter-spacing: 3px;
    color: var(--emu-semantic-colors-secondary-blue-100);
    padding-left: 38px;
    position: relative;

    &::before {
      content: 'Q';
      border: 1px solid var(--emu-semantic-colors-secondary-blue-100);
      border-radius: 50%;
      position: absolute;
      left: -5px;
      padding-top: 1px;
      padding-right: 5px;
      padding-bottom: 2px;
      padding-left: 8px;
      font-size: 18px;
    }

    @include mq('medium') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      line-height: 45px;

      &::before {
        position: relative;
        margin-right: 10px;
        padding-top: 1px;
        padding-right: 3px;
        padding-bottom: 2px;
        padding-left: 6px;
        left: 0;
      }
    }
  }

  &__header {
    padding-top: 20px;

    @include mq('medium') {
      padding-top: var(--emu-semantic-spacing-horizontal);
      padding-bottom: 5px;
    }

    @include mq($emu-semantic-sizing-breakpoints-x-large) {
      padding-top: 10px;
    }
  }

  &__header.-ba-is-active {
    @include mq('medium') {
      padding-bottom: var(--emu-common-spacing-none);
    }

    .aaaem-accordion {
      &__title {
        &::before {
          content: 'A';
          border-color: var(--emu-semantic-colors-primary-red-200);
          color: var(--emu-semantic-colors-primary-red-200);
        }

        @include mq('medium') {
          &::before {
            position: relative;
            margin-right: 10px;
            padding-top: 1px;
            padding-right: 4px;
            padding-bottom: 3px;
            padding-left: 7px;
          }
        }
      }
    }
  }

  &__icon {
    align-self: flex-start;
    @include mq('medium') {
      align-self: center;
    }
  }

  &.js-badger-accordion {
    dd.aaaem-accordion__panel .js-badger-accordion-panel-inner {
      border-bottom: var(
          --emu-component-lists-accordion-item-border-width-bottom-open
        )
        solid
        var(
          --emu-component-lists-accordion-item-color-border-bottom-closed-light
        );
      padding-bottom: 24px;

      .aaaem-text {
        margin: var(--emu-common-spacing-none);
        letter-spacing: 1px;
        line-height: 20px;
        p,
        li {
          color: var(--emu-component-lists-accordion-color-text-light);
        }

        a {
          color: var(--emu-semantic-colors-primary-red-100);
          text-decoration: none;
          letter-spacing: 1.8px;
        }

        p {
          padding-bottom: 10px;
        }

        ul {
          padding-left: var(--emu-semantic-spacing-horizontal);
          li::marker {
            color: var(--emu-semantic-colors-primary-red-200);
          }
        }
      }

      @include mq($emu-semantic-sizing-breakpoints-x-large) {
        padding-right: 115px;
      }
    }
  }
}
